import { Component, OnInit } from '@angular/core';
import { StoreService } from '../store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private store : StoreService, private router : Router) { }

  ngOnInit() {
    this.store.deleteUser();
    this.store.setLoggedStatus(false);
    this.router.navigateByUrl('login');
  }

}
