<div [ngClass]="{ 'pc-view': !isMobile(), 'mobile-view': isMobile() }" *ngIf="product && service">
    <app-header></app-header>

    <div class="d-flex justify-content-between align-items-center header-service pt-4 pb-4 pr-2 pl-2">
        <div class="">
            <p class="display-5 text-white">{{ (operation_title | uppercase) || (product.item_header | translate) }}</p>
            <p class="display-5 text-white">{{ getName(service) | translate | uppercase }}</p>
        </div>
        <img src="{{ service.logourl }}" width="60" height="60" />
    </div>

    <div class="pl-6 pr-6 pt-4 pb-2 d-flex justify-content-center">
        <div class="input-group mb-3 border-bottom border-primary pr-2 pl-2 pt-1 max-w-300">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">XAF</span>
            </div>
            <input
                (focus)="resetFormErrors()"
                [formControl]="amount"
                (input)="onChangeText($event, 'amount')"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
                class="form-control text-right font-size-lg no-border"
                placeholder="0"
                aria-label="amount"
                aria-describedby="basic-addon1"
            />
            <div class="input-group-prepend">
                <div class="d-flex flex-row justify-content-end" *ngIf="amountError">
                    <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="pl-4 pr-4">
        <div class="fees-zone bg-gray-300 d-flex justify-content-between p-3">
            <div class="amount-container flex-fill p-1 pr-2 pr-md-8">
                <div class="fees-container mb-2 d-flex justify-content-between align-items-center">
                    <div class="flex-fill text-xs pr-2">
                        {{ (product.mode === "agent" ? "commission" : "fees") | translate }}
                    </div>
                    <div class="d-flex justify-content-end align-items-center text-xs">
                        <span class="mr-3">XAF</span>
                        {{ ((product.mode === "agent" ? fees_agent : fees) | number) || "$$$$" }}
                    </div>
                </div>
                <div class="total-container d-flex justify-content-between align-items-center">
                    <div class="flex-fill text-xs pr-2">{{ "total" | translate }}</div>
                    <div class="d-flex justify-content-end align-items-center text-xs">
                        <span class="mr-3">XAF</span> {{ (total | number) || "$$$$" }}
                    </div>
                </div>
            </div>
            <div class="button-container d-flex justify-content-end align-items-center">
                <button class="btn btn-dark-soft" (click)="evaluateFees()">
                    {{ (product.mode === "agent" ? "see_commission" : "see_fees") | translate }}
                </button>
            </div>
        </div>

        <div class="reference-zone d-flex flex-column justify-content-center align-items-center mt-7">
            <div class="border-bottom border-primary d-flex align-items-center max-w-300" *ngIf="!service.wallet">
                <div class="input-group-prepend">
                    <div *ngIf="service.international" class="d-flex flex-row justify-content-start">
                        <span class="mdi mdi-phone text-primary mr-3" style="font-size: 20px"></span>
                        <span
                            (click)="changeCountry()"
                            class="mdi mdi-menu-down text-primary mr-1"
                            style="font-size: 20px"
                        ></span>
                        <span class="" (click)="changeCountry()" style="font-size: 20px">{{ country_code }}</span>
                    </div>
                </div>
                <input
                    (focus)="resetFormErrors()"
                    [formControl]="phone"
                    (input)="onChangeText($event, 'phone')"
                    type="text"
                    class="form-control flex-fill no-border"
                    placeholder="{{ getRefLabel(service) }}"
                    aria-label="Text input with dropdown button"
                />
                <div class="input-group-prepend">
                    <div class="d-flex flex-row justify-content-end" *ngIf="phoneError">
                        <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px"></span>
                    </div>
                </div>
            </div>
            <div class="border-bottom border-primary d-flex align-items-center mt-6 max-w-300" *ngIf="!service.wallet">
                <div *ngIf="service.international" class="input-group-prepend">
                    <div class="d-flex flex-row justify-content-start">
                        <span class="mdi mdi-phone text-primary mr-3" style="font-size: 20px"></span>
                        <span
                            (click)="changeCountry()"
                            class="mdi mdi-menu-down text-primary mr-1"
                            style="font-size: 20px"
                        ></span>
                        <span (click)="changeCountry()" class="" style="font-size: 20px">{{ country_code }}</span>
                    </div>
                </div>
                <input
                    (focus)="resetFormErrors()"
                    [formControl]="phoneConfirm"
                    (input)="onChangeText($event, 'phoneConfirm')"
                    type="text"
                    class="form-control flex-fill no-border"
                    placeholder="{{ 'confirmation' | translate }} {{ getRefLabel(service) }}"
                    aria-label="Text input with dropdown button"
                />
                <div class="input-group-prepend">
                    <div class="d-flex flex-row justify-content-end" *ngIf="phoneConfirmError">
                        <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px"></span>
                    </div>
                </div>
            </div>
            <div class="border-bottom border-primary d-flex align-items-center max-w-300" *ngIf="service.wallet">
                <input
                    disabled="disabled"
                    [formControl]="filledPhone"
                    (input)="onChangeText($event, 'filledPhone')"
                    type="text"
                    class="form-control flex-fill no-border"
                    placeholder="{{ getRefLabel(service) }}"
                    aria-label="Text input with dropdown button"
                />
                <div class="input-group-prepend">
                    <div class="d-flex flex-row justify-content-end" *ngIf="phoneError">
                        <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="withCode" class="border-bottom border-primary d-flex align-items-center mt-6 max-w-300">
                <div class="input-group-prepend">
                    <div class="d-flex flex-row justify-content-start">
                        <span class="mdi mdi-lock text-primary mr-3" style="font-size: 20px"></span>
                    </div>
                </div>
                <input
                    (focus)="resetFormErrors()"
                    [formControl]="code"
                    type="text"
                    class="form-control flex-fill no-border"
                    placeholder="{{ 'code' | translate }}"
                    aria-label="Text input with dropdown button"
                />
                <div class="input-group-prepend">
                    <div class="d-flex flex-row justify-content-end" *ngIf="codeError">
                        <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px"></span>
                    </div>
                </div>
            </div>
            <div
                *ngIf="getInstructions(service)"
                class="alert alert-warning col-sm-12 center text-center mt-2 p-2 col-form-label text-xs"
                role="alert"
            >
                <span ng-bing="dico.faites_le">{{ getInstructions(service) }}</span>
            </div>
        </div>

        <div class="button-zone mt-6 d-flex justify-content-center">
            <button class="btn btn-secondary" (click)="fetchCode()" *ngIf="getCode && withCode">
                {{ "resend_code" | translate }}
            </button>
        </div>

        <div class="button-zone mt-1 d-flex justify-content-center">
            <button
                class="btn btn-primary"
                (click)="service.wallet ? toggleModal(2) : withName ? checkRecipient() : toggleModal(2)"
            >
                {{ "proceed" | translate }}
            </button>
        </div>
    </div>

    <div #dialogBoxContainer></div>
</div>

<div
    class="modal customModal"
    data-backdrop="static"
    data-keyboard="false"
    id="modalPayment"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header" *ngIf="modalType === 'processing'">
                <h5 class="modal-title">{{ modalTitle | translate }}</h5>
            </div>
            <div
                class="modal-body"
                [ngClass]="{
                    'bg-danger text-white': modalType === 'danger',
                    'bg-warning  text-white': modalType === 'warning',
                    'bg-success  text-white': modalType === 'success'
                }"
            >
                <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
                    <div>
                        <span
                            *ngIf="modalType === 'processing'"
                            class="spinner-border text-primary spinner-border-lg mr-4"
                        ></span>
                        <span
                            *ngIf="modalType === 'danger'"
                            class="mdi mdi-alert text-white mr-4"
                            style="font-size: 2.5em"
                        ></span>
                        <span
                            *ngIf="modalType === 'warning'"
                            class="mdi mdi-alert-circle-outline text-white mr-4"
                            style="font-size: 2.5em"
                        ></span>
                        <span
                            *ngIf="modalType === 'success'"
                            class="mdi mdi-check-all text-white mr-4"
                            style="font-size: 2.5em"
                        ></span>
                    </div>
                    <span [ngClass]="{ 'text-white': !(modalType === 'processing') }" class="text-xs">{{
                        modalText | translate
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalAlert" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ "failed" | translate }}</h5>
            </div>
            <div class="modal-body">
                {{ modalText | translate }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ "cancel" | translate }}</button>
                <button type="button" (click)="retry()" class="btn btn-primary">{{ "retry" | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalInfo" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ "Info" | translate }}</h5>
            </div>
            <div class="modal-body">
                <span [innerHTML]="instructions"></span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ "cancel" | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal customModal"
    data-backdrop="static"
    data-keyboard="false"
    id="modalDetails"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="modal-title text-white">Infos</h5>
            </div>
            <div class="modal-body">
                <div class="d-flex flex-column p-0 border-top border-gray-600">
                    <span class="text-primary font-weight-bold mb-1"> {{ recipient }} </span>
                    <span class="text-muted">XAF {{ _cleanString(amount.value) | number }}</span>
                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-info btn-sm" (click)="recheckName()">
                            {{ "recheck_name" | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
                    {{ "cancel" | translate }}
                </button>
                <button type="button" *ngIf="showConfirmBtn" class="btn btn-primary btn-sm" (click)="toggleModal(2)">
                    {{ "confirm" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="numpad-back" *ngIf="pPadVisible" [ngClass]="{ 'mobile-pt': isMobile() }">
    <div id="numpad-wrap">
        <div class="bg-primary text-white mb-2 p-2 border-tl-r-5 border-tr-r-5">
            {{ "secret_code" | translate }}
        </div>
        <div class="pad-l-10 pad-r-10">
            <input
                id="numpad-display"
                value="{{ pinCode }}"
                class="border-primary text-gray-700"
                type="password"
                readonly=""
            />
        </div>
        <div id="numpad-btns">
            <div *ngFor="let num of numpad" class="numpad-btn" (click)="addDigit(num)">{{ num }}</div>
            <div class="numpad-btn" (click)="deleteDigit()"><img src="/assets/img/backspace.png" /></div>
        </div>

        <div class="buttons-container mt-4 p-2 d-flex justify-content-between border-gray-600 border-top">
            <button class="btn btn-dark-soft text-xs" (click)="togglePad()">
                {{ "cancel" | translate }}
            </button>
            <button class="btn btn-primary text-xs" (click)="toggleModal(1)">
                {{ "ok" | translate }}
            </button>
        </div>
    </div>
</div>

<div
    class="modal customModal"
    data-backdrop="static"
    data-keyboard="false"
    id="modalCountry"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header" *ngIf="modalType === 'processing'">
                <h5 class="modal-title"></h5>
            </div>
            <div class="modal-body p-0 border-gray-600 border-bottom">
                <div
                    (click)="selectCountry(item)"
                    *ngFor="let item of countries"
                    class="container d-flex pr-5 pr-md-2 flex-row justify-content-between align-items-center border-bottom border-gray-600"
                >
                    <div class="d-flex flex-row align-items-center">
                        <img
                            src="{{ item.logourl }}"
                            alt="logo item.name"
                            style="width: 2em; height: auto; margin-right: 5px"
                        />
                        <span class="text-xs"> {{ getName(item) }} </span>
                    </div>
                    <span class="text-xs">(+{{ item.code }})</span>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
                    {{ "cancel" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
