<div class="side-form"  [ngClass]="{'pc-view': !isMobile() }" >
  <app-header  ></app-header>
  <div class="d-flex justify-content-center align-items-center p-5  header-service ">
    <p class="display-5 text-white">{{this.product.second_title | translate }}</p>
  </div>
  <div *ngIf="product" class="container p-0">
    <div *ngIf="loading" class="container d-flex p-5 mt-1 flex-column justify-content-center align-items-center">
      <span class="spinner-border text-primary spinner-border-sm"></span>
    </div>
    <div *ngIf="services" class="row p-0">
      <div *ngFor="let service of services" class="col-12">
        <a (click)="goTo(service)"
          class="d-flex flex-row align-items-center text-decoration-none not-link pl-2 pr-2 pt-1 pb-1 itemList  border-bottom border-gray-600 ">
          <div class="d-flex justify-content-center align-items-center">
            <img src="{{service.logourl}}" width="50" height="50" class="border-gray-300 mr-3" />
          </div>
          <div class="align-items-center">
            <span class="text-primary text-xs">{{product.item_header | translate}}</span>
            <p *ngIf="!(product.item_header?.length > 0)"></p>
            <p class="display-5 mb-0">{{getName(service)}} </p>
            <span *ngIf="service.wallet" class="text-xs text-black-50" > {{service.wallet.account}} </span>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="view-side">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary d-flex justify-content-center align-items-center">
        <h5 class="modal-title text-center text-white">{{'operation' | translate}}s</h5>
      </div>
      <div class="modal-body p-0">
        <div *ngFor="let item of operations" (click) = "goToPayment(item)" style="cursor: pointer;"
          class="d-flex flex-row  justify-content-between align-items-center p-3 border-gray-600 border-bottom">
          <span class="text-gray-800"> {{getName(item)}} </span>
          <span class="mdi mdi-chevron-right text-primary mr-4" style="font-size: 2em;"></span>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">{{'cancel'| translate}}</button>
      </div>
    </div>
  </div>
</div>


<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalUnavailable" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body bg-primary">
        <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-center align-items-center">
          <span class="text-xs text-white">{{'coming_soon' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
