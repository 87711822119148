import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppRoutingModule, routedComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ServicesComponent } from './services/services.component';
import { PaymentComponent } from './payment/payment.component';
import { BottomTabComponent } from './bottom-tab/bottom-tab.component';
import { AccountComponent } from './account/account.component';
import { SupportComponent } from './support/support.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PaymentBillComponent } from './payment-bill/payment-bill.component';
import { PosComponent } from './pos/pos.component';
import { HomeAgentComponent } from './home-agent/home-agent.component';
import { HistoryAgentComponent } from './history-agent/history-agent.component';
import { HeaderAgentComponent } from './header-agent/header-agent.component';
import { LogoutComponent } from './logout/logout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderHomeComponent } from './header-home/header-home.component';
import { AboutComponent } from './about/about.component';
import { PayrequestComponent } from './payrequest/payrequest.component';
import { AddWalletComponent } from './add-wallet/add-wallet.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { AsyncPaymentComponent } from './async-payment/async-payment.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentSubBillsComponent } from './payment-sub-bills/payment-sub-bills.component';

import { ServiceComponent } from './service/service.component';
import { AgentTransferComponent } from './agent-transfer/agent-transfer.component';
import { PrinterComponent } from './printer/printer.component';
import { CategoryComponent } from './category/category.component';
import { HistoryExportComponent } from './history-export/history-export.component';
import { TransferCommissionComponent } from './transfer-commission/transfer-commission.component';
import { DialogBoxComponent } from './widgets/dialog-box/dialog-box.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        ServicesComponent,
        PaymentComponent,
        BottomTabComponent,
        AccountComponent,
        SupportComponent,
        LoginComponent,
        ResetPasswordComponent,
        PaymentBillComponent,
        PosComponent,
        HomeAgentComponent,
        HistoryAgentComponent,
        HeaderAgentComponent,
        LogoutComponent,
        HeaderHomeComponent,
        AboutComponent,
        PayrequestComponent,
        AddWalletComponent,
        PaymentStatusComponent,
        AsyncPaymentComponent,
        CheckoutComponent,
        PaymentSubBillsComponent,
        ServiceComponent,
        AgentTransferComponent,
        PrinterComponent,
        CategoryComponent,
        HistoryExportComponent,
        TransferCommissionComponent,
        DialogBoxComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
