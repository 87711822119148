
<!-- Modal HTML -->
<div id="myModal" class="modal fade customModal" data-backdrop="static" data-keyboard="false">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header">
				<div class="icon-box bg-success" *ngIf="status=== 'success'" >
                    <i class="material-icons">&#xE876;</i>
                </div>
                <div class="icon-box bg-danger" *ngIf="status=== 'failed'">
                    <i class="material-icons">clear</i>
				</div>				
				<h4 class="modal-title text-center">{{ (status == 'success' ? 'payment_modal_title_success' : 'payment_modal_title_failed') | translate }}</h4>	
			</div>
			<div class="modal-body">
				<p class="text-center"> {{ (status == 'success' ? 'payment_modal_text_success' : 'payment_modal_text_failed') | translate }} </p>
			</div>
			<div class="modal-footer">
				<button (click) = "goHome()" class="btn btn-block" [ngClass]="{'bg-success' : status == 'success', 'bg-danger': status == 'failed'}" >OK</button>
			</div>
		</div>
	</div>
</div>