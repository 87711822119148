<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}" *ngIf="product && service">
  <app-header></app-header>

  <div class="d-flex justify-content-between align-items-center  header-service pt-4 pb-4 pr-2 pl-2">
    <div class="">
      <p class="display-5 text-white">{{product.item_header | translate }}</p>
      <p class="display-5 text-white">{{getName(service) | translate | uppercase }}</p>
    </div>
    <img src="{{service.logourl}}" width="60" height="60" />
  </div>




  <div class="reference-zone d-flex flex-column justify-content-center align-items-center mt-7">
    <div class=" border-bottom border-primary  d-flex align-items-center max-w-300">
      <div class="input-group-prepend">
        <div class="d-flex flex-row justify-content-start">
          <span class="mdi mdi-card-bulleted text-primary mr-3" style="font-size: 20px;"></span>
        </div>
      </div>
      <input (focus)="resetFormErrors()" type="text" [formControl]="billid"
        class="form-control flex-fill no-border" placeholder="{{getRefLabel(service)}}" aria-label="text">
      <div class="input-group-prepend">
        <div class="d-flex flex-row justify-content-end" *ngIf="billidError">
          <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
        </div>
      </div>
    </div>

  </div>


  <div class="button-zone mt-6 d-flex  justify-content-center">

    <button class="btn btn-primary btn-sm" (click)="print()">
      {{'download' | translate}}
    </button>
  </div>

</div>


