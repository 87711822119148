<div class="main_container">
    <app-header-agent></app-header-agent>
    <app-bottom-tab></app-bottom-tab>
    <div id="balance-container">
        <div class="d-flex flex-column p-md-5 p-2">
            <div class="">
                <p class="text-primary">
                    <span class="font-weight-bold">{{ user.agent.agentname }}</span> <br />
                    <span class="text-xs">{{ user.agent.idagent }}</span>
                </p>
            </div>
            <div class="align-items-end">
                <p class="text-primary text-right">
                    <span> {{ "main_balance" | translate }} : XAF {{ (mainDeposit | number) || "$$$$" }}</span> <br />
                    <span class="text-xs text-primary"
                        >{{ "commission" | translate }} : XAF {{ (mainBalance | number) || "$$$$" }}</span
                    >
                </p>
                <div class="d-flex flex-row justify-content-end">
                    <button
                        class="btn btn-info text-white shadow btn-sm mr-2"
                        (click)="goTo('home/transfer-commission', null)"
                    >
                        <span class="text-white text-xs"> {{ "transfer_commission" | translate }} </span>
                    </button>
                    <button class="btn btn-primary text-white shadow btn-sm" (click)="balance()">
                        <span class="text-white text-xs"> {{ "balance_info" | translate }} </span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row pt-0 pt-md-5 p-2 pb-10 bg-primary">
        <div class="col-12 col-md-3 col-lg-2 border-right border-white pt-0">
            <div class="row">
                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goToService(products.recharge)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <span class="mdi mdi-wallet-outline text-white" style="font-size: 40px"></span>
                        <span
                            class="text-left text-xs align-middle pl-md-2 pl-sm-2 d-none d-sm-none d-md-block text-white"
                        >
                            {{ "self_recharge" | translate }}</span
                        >
                        <span
                            class="text-center text-xs align-middle pl-md-2 pl-sm-2 d-block d-sm-block d-md-none text-white"
                        >
                            {{ "self_recharge" | translate }}
                        </span>
                    </a>
                </div>
                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goToService(products.airtime)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <i class="icon ion-ios-cellular text-white" style="font-size: 40px"></i>
                        <span
                            class="text-left text-xs align-middle pl-md-2 pl-sm-2 d-none d-sm-none d-md-block text-white"
                        >
                            {{ "airtime" | translate }}</span
                        >
                        <span
                            class="text-center text-xs align-middle pl-md-2 pl-sm-2 d-block d-sm-block d-md-none text-white"
                        >
                            {{ "airtime" | translate }}
                        </span>
                    </a>
                </div>
                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goToService(products.momo)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <span class="mdi mdi-cash-usd-outline text-white" style="font-size: 40px"></span>
                        <span
                            class="text-left text-xs align-middle pl-md-2 pl-sm-2 d-none d-sm-none d-md-block text-white"
                        >
                            {{ "mobile_money" | translate }}</span
                        >
                        <span
                            class="text-center text-xs align-middle pl-md-2 pl-sm-2 d-block d-sm-block d-md-none text-white"
                        >
                            {{ "mobile_money" | translate }}
                        </span>
                    </a>
                </div>
            </div>

            <div class="row pt-sm-0 pt-md-0 pt-4">
                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goToService(products.bill)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <span class="mdi mdi-receipt text-white" style="font-size: 40px"></span>
                        <span
                            class="text-left text-xs align-middle pl-md-2 pl-sm-2 d-none d-sm-none d-md-block text-white"
                        >
                            {{ "bill_payment" | translate }}</span
                        >
                        <span
                            class="text-center text-xs align-middle pl-md-2 pl-sm-2 d-block d-sm-block d-md-none text-white"
                        >
                            {{ "bill_payment" | translate }}
                        </span>
                    </a>
                </div>
                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goToService(products.service)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <i class="mdi mdi-widgets text-white" style="font-size: 40px"></i>
                        <span
                            class="text-left text-xs align-middle pl-md-2 pl-sm-2 d-none d-sm-none d-md-block text-white"
                        >
                            {{ "Services" | translate }}</span
                        >
                        <span
                            class="text-center text-xs align-middle pl-md-2 pl-sm-2 d-block d-sm-block d-md-none text-white"
                        >
                            {{ "Services" | translate }}
                        </span>
                    </a>
                </div>
                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goToService(products.school)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <span class="mdi mdi-school text-white" style="font-size: 40px"></span>
                        <span
                            class="text-left text-xs align-middle pl-md-2 pl-sm-2 d-none d-sm-none d-md-block text-white"
                        >
                            {{ "tuition_fees" | translate }}</span
                        >
                        <span
                            class="text-center text-xs align-middle pl-md-2 pl-sm-2 d-block d-sm-block d-md-none text-white"
                        >
                            {{ "tuition_fees" | translate }}
                        </span>
                    </a>
                </div>
            </div>

            <div class="row pt-sm-0 pt-md-0 pt-4" *ngIf="!isMobile()">
                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goTo('home/category', null)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <span class="mdi mdi-printer text-white" style="font-size: 40px"></span>
                        <span
                            class="text-left text-xs align-middle pl-md-2 pl-sm-2 d-none d-sm-none d-md-block text-white"
                        >
                            {{ "print_download" | translate }}</span
                        >
                    </a>
                </div>
            </div>

            <div class="row pt-sm-0 pt-md-0 pt-4" *ngIf="isMobile()">
                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goTo('home/agent-transfer', null)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <span class="mdi mdi-account-arrow-right text-white" style="font-size: 40px"></span>
                        <span class="text-center text-xs d-block text-white"> {{ "agent_transfer" | translate }}</span>
                    </a>
                </div>

                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goTo('home/history', null)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <i class="mdi mdi-history text-white" style="font-size: 40px"></i>
                        <span class="text-center text-xs d-block text-white"> {{ "history" | translate }} </span>
                    </a>
                </div>

                <div class="col-md-12 col-sm-4 col-4">
                    <a
                        (click)="goTo('home/category', null)"
                        class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link"
                    >
                        <span class="mdi mdi-printer text-white" style="font-size: 40px"></span>
                        <span class="text-center text-xs d-block text-white"> {{ "print_download" | translate }}</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-9 col-lg-10 d-none d-md-block">
            <div *ngIf="product" class="container">
                <p class="display-4 text-white-80">{{ this.product.second_title | translate }}</p>
                <div
                    *ngIf="loading && request === 'operators'"
                    class="container d-flex p-5 flex-column justify-content-center align-items-center"
                >
                    <span class="spinner-border text-white-75 spinner-border-sm"></span>
                </div>

                <div *ngIf="services" class="row mt-2">
                    <div *ngFor="let service of services" class="col-md-4 col-sm-6 col-4">
                        <a
                            (click)="goToPayment(service)"
                            class="d-flex flex-md-row flex-column align-items-center text-decoration-none not-link pt-md-1"
                        >
                            <img src="{{ service.logourl }}" width="60" height="60" class="border-gray-800 mr-3" />
                            <div class="align-items-center">
                                <span class="text-white-70 text-xs">{{ product.item_header | translate }}</span>
                                <p *ngIf="service.wallet" class="display-5 mb-0 text-white">{{ getName(service) }}</p>
                                <p *ngIf="!service.wallet" class="display-5 text-white">{{ getName(service) }}</p>
                                <span *ngIf="service.wallet" class="text-xs text-white-50">
                                    {{ service.wallet.account }}
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div *ngIf="!product" class="container">
                <p class="display-4 text-white">
                    <span class="mdi mdi-arrow-left text-white mr-3" style="font-size: 30px"></span>
                    {{ "select_service" | translate }}
                </p>
                <div class="row d-flex justify-content-center align-items-center" id="bg_empty">
                    <img src="/assets/img/bg_empty.png" alt="bg empty" />
                </div>
            </div>
        </div>
    </div>

    <div class="view-side-form">
        <router-outlet></router-outlet>
    </div>

    <div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header" *ngIf="modalType === 'processing'">
                    <h5 class="modal-title">{{ modalTitle | translate }}</h5>
                </div>
                <div
                    class="modal-body"
                    [ngClass]="{
                        'bg-danger text-white': modalType === 'danger',
                        'bg-warning  text-white': modalType === 'warning'
                    }"
                >
                    <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
                        <div>
                            <span
                                *ngIf="modalType === 'processing'"
                                class="spinner-border text-primary spinner-border-lg mr-4"
                            ></span>
                            <span
                                *ngIf="modalType === 'danger'"
                                class="mdi mdi-alert text-white mr-4"
                                style="font-size: 2.5em"
                            ></span>
                            <span
                                *ngIf="modalType === 'warning'"
                                class="mdi mdi-alert-circle-outline text-white mr-4"
                                style="font-size: 2.5em"
                            ></span>
                        </div>
                        <span [ngClass]="{ 'text-white': !(modalType === 'processing') }" class="text-xs">{{
                            modalText | translate
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal customModal"
        data-backdrop="static"
        data-keyboard="false"
        id="modalList"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header bg-primary d-flex justify-content-center align-items-center">
                    <h5 class="modal-title text-center text-white">{{ "operation" | translate }}s</h5>
                </div>
                <div class="modal-body p-0">
                    <div
                        *ngFor="let item of operations"
                        (click)="chooseOperation(item)"
                        style="cursor: pointer"
                        class="d-flex flex-row justify-content-between align-items-center p-3 border-gray-600 border-bottom"
                    >
                        <span class="text-gray-800"> {{ getName(item) }} </span>
                        <span class="mdi mdi-chevron-right text-primary mr-4" style="font-size: 2em"></span>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">
                        {{ "cancel" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal customModal"
        data-backdrop="static"
        data-keyboard="false"
        id="modalUnavailable"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-body bg-primary">
                    <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-center align-items-center">
                        <span class="text-xs text-white">{{ "coming_soon" | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
