<div class="side-form"  [ngClass]="{'pc-view': !isMobile() }" >
  <app-header></app-header>
  <div class="d-flex justify-content-center align-items-center p-5  header-service ">
    <p class="display-5 text-white">{{'category' | translate }}</p>
  </div>
  <div *ngIf="products" class="container p-0">
    <div class="row p-0">
      <div *ngFor="let product of products" class="col-12">
        <a (click)="goTo(product)"
          class="d-flex flex-row align-items-center text-decoration-none not-link pl-2 pr-2 pt-3 pb-3 itemList  border-bottom border-gray-600 ">
          <div class="d-flex justify-content-center align-items-center mr-3" style='font-size: 2rem !important;' [innerHTML]="product.icon"> </div>
          <div class="align-items-center">
            <p class="display-5 mb-0">{{product.title | translate}} </p>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="view-side">
    <router-outlet></router-outlet>
  </div>
</div>

