import { Component, OnInit } from '@angular/core';
import {HelperService} from '../helper.service';
import {TranslateService} from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {HttpService} from '../http.service';
    import {Md5} from 'ts-md5';
import {Router} from '@angular/router';
import {StoreService} from '../store.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  modalTitle: string;
	modalText: string;
  modalType: string;
  lang: string;

  phoneError = false;
  passwordError = false;
  isPasswordVisible = false;

  loginForm: FormGroup;
  phoneLength = 9;
  country_code = '237';
  countries = [];
  isOtpGenerated: boolean;

  constructor(private helper: HelperService, private translate: TranslateService,
              private formBuilder: FormBuilder , private http: HttpService, private router: Router, private store: StoreService) { }

  ngOnInit() {

    if (this.store.getLoggedStatus()) {
      this.router.navigateByUrl('home');
    }

    this.loginForm = this.formBuilder.group({
      phone: ['', [Validators.required]],
      password: ['', [Validators.required]],
      otp: ['', [Validators.maxLength(6)]]
    });

    this.lang = localStorage.getItem('lang');

  }

  getCountries() {
    this.http.getCountries().subscribe((response: any) => {
      if (response) {
       if (response.code === 200) {
        this.countries = response.result;
       }
      }
    });
  }

  selectCountry(country: any) {
    this.country_code = country.code;
    this.phoneLength = country.nationcounttel;
    $('#modalCountry').modal('hide');
  }

  changeCountry() {
    $('#modalCountry').modal('show');
  }

  login() {
    const phone = this.loginForm.controls.phone.value;
    const password = this.loginForm.controls.password.value;
    this.phoneError = this.loginForm.controls.phone.invalid;
    this.passwordError = this.loginForm.controls.password.invalid;
    if (localStorage.getItem('attemptedNumber') == null) {
      localStorage.setItem('attemptedNumber', '0');
    }

    if (!this.loginForm.invalid) {
      if (navigator.onLine) {
        this.setModal('processing', '', 'auth');
        $('#modal').modal('toggle');
        this.http.login(phone, Md5.hashStr(password).toString(), this.lang).subscribe((response: any) => {
            if (response) {
              if (response.code === 200) {
                // generate otp
                this.isOtpGenerated = true;
                const result = response.result;
                this.http.generateOtp(result.email).subscribe((response: any) => {
                  if (response.code === 200 && response.message === 'Success') {
                    localStorage.removeItem('item1');
                    localStorage.removeItem('item2');
                    localStorage.removeItem('item3');
                    const user = {
                      agent : result
                    };
                    this.store.saveUser(user);
                    this.dismissModal(2500, null);
                  } else {
                    this.setModal('danger', response.message || '', 'OTP');
                    this.dismissModal(2500, null);
                  }
                });
              } else {
                let attemptedNumber = parseInt(localStorage.getItem('attemptedNumber'), 10);
                localStorage.setItem('attemptedNumber', String(++attemptedNumber));
                localStorage.setItem('item' + attemptedNumber, phone);
                if (attemptedNumber === 3) {
                  localStorage.setItem('attemptedNumber', '0');
                  if ((localStorage.getItem('item1') === localStorage.getItem('item2'))
                    && (localStorage.getItem('item2') === localStorage.getItem('item3'))){
                    this.lockAccount(phone);
                  }
                }
                this.setModal('danger', response.message || '', '');
                this.dismissModal(2500, null);
              }
            } else {
              this.setModal('warning', 'Logging error', '');
              this.dismissModal(2500, null);
            }
          },
          (error: any) => {
            if (error) {
              this.setModal('warning', 'failed', '');
              this.dismissModal(2500, null);
            }
          });
        }

    }
  }

  lockAccount(username: string) {
    this.http.lockAccount(username).subscribe((response: any) => {
      if (response) {
        if (response.code === 200) {
          this.setModal('danger', 'Logging error: Your account has been locked, ' +
            'maximum number of connection attempts encountered. Please contact your admin to unlock your account', '');
          this.dismissModal(2500, null);
        } else {
          this.setModal('danger', 'Logging error: Error to lock account: ' + response.message, 'Error to lock account');
          this.dismissModal(2500, null);
        }
      } else {
        this.setModal('danger', 'Logging error: Error to lock account', 'Api Error to lock account');
        this.dismissModal(2500, null);
      }
    });
  }

  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].name || service.name;
      }
    }
    return service.name;
  }

  resetFormErrors() {
    this.phoneError = false;
    this.passwordError = false;
  }

  setModal(type: string, text: string, title: string) {

    this.modalType = type;

    switch (type) {
      case 'processing' : {
        this.modalTitle = title;
        this.translate.get('please_wait').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'warning' : {
        this.modalTitle = '';
        this.translate.get('failed').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'danger' : {
        this.modalTitle = '';
        this.modalText = text;
        break;
      }

      default : break;
    }

  }

  dismissModal(timer: number, dest: string) {
    setTimeout(() => {
      $('#modal').modal('toggle');
      (dest) ? this.router.navigateByUrl(dest) : null;
    }, timer);
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  isMobile() {
    return this.helper.isMobile();
  }


  verifyOtp() {
    const otp = this.loginForm.controls.otp.value;
    this.setModal('processing', '', 'OTP verification');
    this.dismissModal(2500, null);
    this.http.verifyOtp(otp).subscribe((response: any) => {
      if (response.code === 200 && response.message === 'Success') {
        this.store.setLoggedStatus(true);
        this.dismissModal(2500, '/home');
      } else {
        this.setModal('warning', '', response.message);
        this.dismissModal(2500, null);
      }
    });
  }
}
