<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">
    <app-header></app-header>
  
    <div class="d-flex justify-content-center align-items-center p-5  header ">
      <span class="text-white d-block  text-center"> {{ 'payment_request' | translate }} </span>
    </div>
    <div class="row p-0 border-top border-gray-600" *ngIf="list?.length > 0">
      <div *ngFor="let item of list; let i= index" class="col-12 border-bottom border-gray-600 p-2 pr-5 pl-5">

          <span class="text-xs text-black mb-2 p-2"> {{ 'transaction_initiated_by' | translate }} {{item.agentname}}</span>
          <div class="col-12 d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex flex-column justify-content-between">
              <span class="text-primary font-weight-bold">XAF {{item.amount | number}}</span>
              <div class="d-flex flex-row align-items-center">
                  <span class="text-muted text-xs  mt-2">{{getDate(item.date)}}</span>
              </div>
            </div>
            <div class="d-flex flex-column">
                <span class="text-gray-600 text-right">ID {{item.id}}</span>
            </div>
          </div>

          <div class="d-flex d-row justify-content-end mt-2">
            <button class="btn btn-danger-soft btn-sm mr-3" (click)="changeStatus('CANCEL', i)">
              {{'reject' | translate}}
            </button>
            <button class="btn btn-success btn-sm" (click)="changeStatus('SUCCESS', i)">
              {{'approved' | translate}}
            </button>
          </div>

      </div>
    </div>

    
  <div class="justify-content-center p-5" *ngIf="!(list?.length >0) && list_loaded " >
    <p class="text-xs center text-center d-block">
      {{'no_data_found' | translate}}
    </p>
  </div>
  <div class="d-flex justify-content-center mt-3"   *ngIf="list_loaded">
    <button class="btn btn-primary-soft btn-sm" (click)="loadRequest()">
      {{'refresh' | translate}}
    </button>
  </div>
  </div>
  <app-bottom-tab></app-bottom-tab>
  



  <div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalPayment" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="modalType==='processing'">
        <h5 class="modal-title">{{modalTitle | translate}}</h5>
      </div>
      <div class="modal-body"
        [ngClass]="{'bg-danger text-white': (modalType === 'danger'), 'bg-warning  text-white' : (modalType === 'warning') , 'bg-success  text-white' : (modalType === 'success') }">
        <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
          <div>
            <span *ngIf="modalType === 'processing'" class="spinner-border text-primary spinner-border-lg mr-4 "></span>
            <span *ngIf="modalType === 'danger'" class="mdi mdi-alert text-white mr-4" style="font-size: 2.5em;"></span>
            <span *ngIf="modalType === 'warning'" class="mdi mdi-alert-circle-outline text-white mr-4"
              style="font-size: 2.5em;"></span>
              <span *ngIf="modalType === 'success'" class="mdi mdi-check-all text-white mr-4" style="font-size: 2.5em;"></span>
          </div>
          <span [ngClass]=" {'text-white' : !(modalType==='processing')} "
            class="text-xs">{{modalText | translate}}</span>
        </div>

      </div>
    </div>
  </div>
</div>


<div id="numpad-back" *ngIf="pPadVisible" [ngClass]="{'mobile-pt': isMobile()}">
  <div id="numpad-wrap">
    <div class="bg-primary text-white mb-2 p-2 border-tl-r-5 border-tr-r-5">
      {{ 'pin_code' | translate}}
    </div>
    <div class="pad-l-10 pad-r-10">
      <input id="numpad-display" value="{{pinCode}}" class="border-primary text-gray-700" type="password" readonly="">
    </div>
    <div id="numpad-btns">
      <div *ngFor="let num of numpad" class="numpad-btn" (click)="addDigit(num)">{{num}}</div>
      <div class="numpad-btn" (click)="deleteDigit()"> <img src="/assets/img/backspace.png" /> </div>
    </div>

    <div class="buttons-container mt-4 p-2 d-flex justify-content-between border-gray-600 border-top">
      <button class="btn btn-dark-soft text-xs" (click)="togglePad(false)">
        {{'cancel' | translate}}
      </button>
      <button class="btn btn-primary text-xs" (click)="setTransaction()">
        {{'ok' | translate}}
      </button>
    </div>
  </div>
</div>