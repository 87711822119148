<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">

  <app-header></app-header>
  <div class="d-flex justify-content-center align-items-center  header-service pt-4">
    <p class=" font-size-sm text-white">{{placeholder.title | translate | uppercase }}</p>
  </div>


  <div class="reference-zone d-flex flex-column justify-content-center align-items-center mt-7">
    <div class=" border-bottom border-primary  d-flex align-items-center max-w-300">
      <div class="input-group-prepend">
        <div class="d-flex flex-row justify-content-start">
          <span class="mdi mdi-lock text-primary mr-3" style="font-size: 20px;"></span>
        </div>
      </div>
      <input type="{{(isCurrentVisible) ? 'text': 'password' }}" (focus)="resetFormErrors()" [formControl]="current"
        maxlength="{{getMaxLenght()}}" class="form-control flex-fill no-border"
        placeholder="{{ placeholder.current | translate }}" aria-label="Text input with dropdown button">
      <div class="input-group-prepend">
        <div class="d-flex flex-row justify-content-end">
          <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;" *ngIf="currentError"></span>
          <span (click)="toggle('current')"
            [ngClass]="{'mdi-eye-outline' : !isCurrentVisible, 'mdi-eye-off-outline' : isCurrentVisible}"
            class="mdi mdi-eye-outline text-primary mr-1" style="font-size: 20px;"></span>
        </div>
      </div>
    </div>
    <span class="text-xs text-danger right" *ngIf="currentError && ( operation === 'password') "> {{'incorrect_password' | translate}}</span>
    <div class="border-bottom border-primary d-flex align-items-center mt-6 max-w-300">
      <div class="input-group-prepend">
        <div class="d-flex flex-row justify-content-start">
          <span class="mdi mdi-lock text-primary mr-3" style="font-size: 20px;"></span>
        </div>
      </div>
      <input type="{{(isNewVisible) ? 'text': 'password' }}" (focus)="resetFormErrors()" [formControl]="new"
        maxlength="{{getMaxLenght()}}" class="form-control flex-fill no-border"
        placeholder="{{ placeholder.new | translate }}" aria-label="Text input with dropdown button">
      <div class="input-group-prepend">
        <div class="d-flex flex-row justify-content-end">
          <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;" *ngIf="newError"></span>
          <span (click)="toggle('new')"
            [ngClass]="{'mdi-eye-outline' : !isNewVisible, 'mdi-eye-off-outline' : isNewVisible}"
            class="mdi mdi-eye-outline text-primary mr-1" style="font-size: 20px;"></span>
        </div>
      </div>
    </div>
    <span class="text-xs text-danger right" *ngIf="newError && ( operation === 'password') "> {{'at_least' | translate}} 6 {{'character' | translate}}s  </span>
    <span class="text-xs text-danger right" *ngIf="newError && ( operation === 'pin') "> {{'should_have' | translate}} 4 {{'digit' | translate}}s  </span>
    <div class="border-bottom border-primary d-flex align-items-center mt-6 max-w-300">
      <div class="input-group-prepend">
        <div class="d-flex flex-row justify-content-start">
          <span class="mdi mdi-lock text-primary mr-3" style="font-size: 20px;"></span>
        </div>
      </div>
      <input type="{{(isConfirmVisible) ? 'text': 'password' }}" (focus)="resetFormErrors()" [formControl]="confirm"
        maxlength="{{getMaxLenght()}}" class="form-control flex-fill no-border"
        placeholder="{{ placeholder.confirm | translate }}" aria-label="Text input with dropdown button">
      <div class="input-group-prepend">
        <div class="d-flex flex-row justify-content-end">
          <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;" *ngIf="confirmError"></span>
          <span (click)="toggle('confirm')"
            [ngClass]="{'mdi-eye-outline' : !isConfirmVisible, 'mdi-eye-off-outline' : isConfirmVisible}"
            class="mdi mdi-eye-outline text-primary mr-1" style="font-size: 20px;"></span>
        </div>
      </div>
    </div>
    <span  class="text-xs text-danger right" *ngIf="confirmError" > {{operation | translate}} {{'does_not_match' | translate}} </span>
  </div>

  <div class="button-zone mt-6 d-flex justify-content-center">
    <button class="btn btn-primary" (click)="change()">
      {{'proceed' | translate}}
    </button>
  </div>

</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalChange" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="modalType==='processing'">
        <h5 class="modal-title">{{modalTitle | translate}}</h5>
      </div>
      <div class="modal-body"
        [ngClass]="{'bg-danger text-white': (modalType === 'danger'), 'bg-warning  text-white' : (modalType === 'warning') , 'bg-success  text-white' : (modalType === 'success') }">
        <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
          <div>
            <span *ngIf="modalType === 'processing'" class="spinner-border text-primary spinner-border-lg mr-4 "></span>
            <span *ngIf="modalType === 'danger'" class="mdi mdi-alert text-white mr-4" style="font-size: 2.5em;"></span>
            <span *ngIf="modalType === 'warning'" class="mdi mdi-alert-circle-outline text-white mr-4"
              style="font-size: 2.5em;"></span>
            <span *ngIf="modalType === 'success'" class="mdi mdi-check-all text-white mr-4"
              style="font-size: 2.5em;"></span>
          </div>
          <span [ngClass]=" {'text-white' : !(modalType==='processing')} "
            class="text-xs">{{modalText | translate}}</span>
        </div>

      </div>
    </div>
  </div>
</div>
