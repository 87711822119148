<div [ngClass]="{ 'pc-view': !isMobile(), 'mobile-view': isMobile() }">
    <app-header></app-header>
    <div
        *ngIf="product && service"
        class="d-flex justify-content-between align-items-center header-service pt-4 pb-4 pr-2 pl-2"
    >
        <div class="">
            <p class="display-5 text-white">{{ product.item_header | translate }}</p>
            <p class="display-5 text-white">{{ billid | uppercase }}</p>
        </div>
        <img src="{{ service.logourl }}" width="60" height="60" />
    </div>

    <div class="p-0 border-top border-gray-600">
        <div
            class="col-12 border-bottom border-gray-600 p-2 pr-3 pl-3 d-flex justify-content-between"
            style="width: 100%"
            *ngFor="let item of options; let i = index"
        >
            <div class="">
                <i class="mdi mdi-format-list-text text-primary mr-1" style="font-size: 18px"></i>
                <span class="text-primary font-weight-bold" style="font-size: 18px"> {{ item.name }} </span><br />
                <h4 class="text-black-50 pl-2 font-weight-bold">XAF {{ item.amount | number }}</h4>
            </div>

            <div class="d-flex justify-content-end align-items-center">
                <button
                    class="btn btn-primary btn-sm"
                    style="height: fit-content !important"
                    (click)="toggleModal(2, item)"
                >
                    {{ "pay" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div
        class="modal customModal"
        data-backdrop="static"
        data-keyboard="false"
        id="modalSubBillPayment"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header" *ngIf="modalType === 'processing'">
                    <h5 class="modal-title">{{ modalTitle | translate }}</h5>
                </div>
                <div
                    class="modal-body"
                    [ngClass]="{
                        'bg-danger text-white': modalType === 'danger',
                        'bg-warning  text-white': modalType === 'warning',
                        'bg-success  text-white': modalType === 'success'
                    }"
                >
                    <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
                        <div>
                            <span
                                *ngIf="modalType === 'processing'"
                                class="spinner-border text-primary spinner-border-lg mr-4"
                            ></span>
                            <span
                                *ngIf="modalType === 'danger'"
                                class="mdi mdi-alert text-white mr-4"
                                style="font-size: 2.5em"
                            ></span>
                            <span
                                *ngIf="modalType === 'warning'"
                                class="mdi mdi-alert-circle-outline text-white mr-4"
                                style="font-size: 2.5em"
                            ></span>
                            <span
                                *ngIf="modalType === 'success'"
                                class="mdi mdi-check-all text-white mr-4"
                                style="font-size: 2.5em"
                            ></span>
                        </div>
                        <span [ngClass]="{ 'text-white': !(modalType === 'processing') }" class="text-xs">{{
                            modalText | translate
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal customModal"
        data-backdrop="static"
        data-keyboard="false"
        id="modalBillsAlert"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ "failed" | translate }}</h5>
                </div>
                <div class="modal-body">
                    {{ modalText | translate }}
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="cancel_listing()" class="btn btn-secondary" data-dismiss="modal">
                        {{ "cancel" | translate }}
                    </button>
                    <button type="button" (click)="retry_listing()" class="btn btn-primary" data-dismiss="modal">
                        {{ "retry" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal customModal"
        data-backdrop="static"
        data-keyboard="false"
        id="modalBillAlert"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ "failed" | translate }}</h5>
                </div>
                <div class="modal-body">
                    {{ modalText | translate }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        {{ "cancel" | translate }}
                    </button>
                    <button type="button" (click)="retry()" class="btn btn-primary" data-dismiss="modal">
                        {{ "retry" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div id="numpad-back" *ngIf="pPadVisible" [ngClass]="{ 'mobile-pt': isMobile() }">
        <div id="numpad-wrap">
            <div class="bg-primary text-white mb-2 p-2 border-tl-r-5 border-tr-r-5">
                {{ "secret_code" | translate }}
            </div>
            <div class="pad-l-10 pad-r-10">
                <input
                    id="numpad-display"
                    value="{{ pinCode }}"
                    class="border-primary text-gray-700"
                    type="password"
                    readonly=""
                />
            </div>
            <div id="numpad-btns">
                <div *ngFor="let num of numpad" class="numpad-btn" (click)="addDigit(num)">{{ num }}</div>
                <div class="numpad-btn" (click)="deleteDigit()"><img src="/assets/img/backspace.png" /></div>
            </div>

            <div class="buttons-container mt-4 p-2 d-flex justify-content-between border-gray-600 border-top">
                <button class="btn btn-dark-soft text-xs" (click)="togglePad()">
                    {{ "cancel" | translate }}
                </button>
                <button class="btn btn-primary text-xs" (click)="toggleModal(1, null)">
                    {{ "ok" | translate }}
                </button>
            </div>
        </div>
    </div>

    <div
        class="modal customModal"
        data-backdrop="static"
        data-keyboard="false"
        id="modalManuals"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ "Info" | translate }}</h5>
                </div>
                <div class="modal-body">
                    {{ modalText | translate }}
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="quit()" class="btn btn-primary">{{ "Ok" | translate }}</button>
                </div>
            </div>
        </div>
    </div>

    <div #dialogBoxContainer></div>
</div>
