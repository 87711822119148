<!-- NAVBAR
    ================================================== -->
<nav class="navbar navbar-expand-lg navbar-togglable" *ngIf="!(currentRoute === 'login') ">
  <div class="container-fluid">

    <!-- Brand -->
    <a class="navbar-brand d-flex align-items-center pb-3" (click)="goBack()">
      <span *ngIf="!isHome()" class="mdi mdi-chevron-left text-primary" style="font-size: 40px;"></span>
      <img src="https://www.afrikpay.com/assets/img/logo.png" class="navbar-brand-img" alt="Logo AfrikPay">
    </a>


    <!-- Collapse -->
    <div class="collapse navbar-collapse" id="navbarCollapse">

      <!-- Navigation -->
      <ul class="navbar-nav ml-auto pt-2" *ngIf="isHome()">
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center text-decoration-none" (click)="goTo('home/history')">
            <i class="mdi mdi-history  text-primary mr-2" style="font-size: 16px;"></i> {{'history'| translate}}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center text-decoration-none" (click)="goTo('home/account')">
            <i class="mdi mdi-account  text-primary mr-2" style="font-size: 16px;"></i> {{'account_settings'| translate}}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center text-decoration-none" (click)="goTo('home/pos')">
            <i class="mdi mdi-account-search-outline  text-primary mr-2" style="font-size: 16px;"></i> {{'pos'| translate}}
          </a>
        </li>
      </ul>

    </div>

  </div>
</nav>
