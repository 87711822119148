import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Md5 } from 'ts-md5';
import { environment } from '../environments/environment';
import { StoreService } from './store.service';
import { Router } from '@angular/router';

const agentid = environment.agentid;
const agentpwd = environment.agentpwd;
const apikey = environment.apikey;

const baseURL = environment.targetAPI;
//const sandBoxURL = environment.sandboxApi;
let endpoints = [];
endpoints['airtime'] = 'airtime/getoperator/';
endpoints['recharge'] = 'money/getmethod/';
endpoints['momo'] = 'money/getmethod/';
endpoints['bill'] = 'bill/billers/';

let urls = [];
urls['airtime'] = 'account/agent/airtime/';
urls['recharge'] = 'account/recharge/v2/';
urls['momo'] = 'account/transfer/v2/';
urls['bill'] = 'account/agent/bill/';

let ops = [];
ops['password'] = 'account/modifypassword/';
ops['pin'] = 'account/pinupdate/';
ops['secret_code'] = 'account/agent/changecode/';

let historyType = [];
historyType['client'] = 'account/history/';
historyType['agent'] = 'account/agent/history/v2/';

let balances = [];
balances['client'] = 'account/balance/';
balances['agent'] = 'account/agent/balance/v2/';

@Injectable({
    providedIn: 'root',
})
export class HttpService implements OnInit {
    langage: string;
    terminaltype = 'web';
    identify = environment.id;

    constructor(private http: HttpClient, private store: StoreService, private router: Router) {}

    ngOnInit(): void {
        this.langage = localStorage.getItem('lang');
    }

    login(username: string, secretcode: string, lang: string): any {
        localStorage.setItem('timeout', Date.now().toString());
        return this.http.post(baseURL + 'account/agent/login/web/', {
            username,
            secretcode,
            lang,
            terminaltype: this.terminaltype,
        });
    }

    lockAccount(username: string): any {
        return this.http.post(baseURL + 'account/agent/lock/', {
            username,
        });
    }

    getCountries() {
        this.resetAutoLogout();
        const lang = localStorage.getItem('lang');
        const params = new HttpParams({
            fromObject: { lang, terminaltype: this.terminaltype, identify: this.identify },
        });
        return this.http.get(baseURL + 'intl/countries/', { params: params });
    }

    autoLogout() {
        const currentDate = Date.now();
        const storeDate = localStorage.getItem('timeout');
        if (currentDate - parseInt(storeDate, 10) > 60000 * 30) {
            this.store.deleteUser();
            this.store.setLoggedStatus(false);
            this.router.navigateByUrl('login');
        }
    }

    resetAutoLogout() {
        localStorage.setItem('timeout', Date.now().toString());
    }

    exec(data, service: string, urlReq: string, great: string, category: string) {
        this.resetAutoLogout();
        data.agentpwd = data.agentpwd || agentpwd;
        data.agentid = data.agentid || agentid;
        data.mode = 'cash';
        data.currency = 952;
        data.type = data.type || '';
        data.lang = this.langage;
        data.agentplatform = agentid;
        data.version = 7;
        if (!data.amount) data.amount = '';
        var date = new Date();

        let url = urls[service];

        if (service === 'momo') {
            data.hash = Md5.hashStr(data.client + data.amount + apikey).toString();
        }

        if (service === 'airtime') {
            data.hash = Md5.hashStr(data.operator + data.reference + data.amount + apikey).toString();
        }

        if (service === 'bill') {
            if (data.amount) {
                data.hash = Md5.hashStr(data.biller + data.billid + data.amount + apikey).toString();
            } else {
                data.hash = Md5.hashStr(data.biller + data.billid + apikey).toString();
            }
        }

        if ((category === 'cashin' || category === 'cashout') && great !== 'afrikpay') {
            data.hash = Md5.hashStr(data.provider + data.payref + data.amount + apikey).toString();
            url = 'account/recharge/v2/';
        }

        if ((data.type === 'cashout' || data.type === 'cashin') && data.mode === 'cash') {
            data.hash = Md5.hashStr(data.provider + data.payref + data.amount + apikey).toString();
            url = 'account/agent/recharge/V2/';
        }

        return this.http.post(baseURL + url, data);
    }

    cashoutExec(data, service, urlReq, great, category) {
        this.resetAutoLogout();
        data.agentpwd = data.agentpwd || agentpwd;
        data.agentid = data.agentid || agentid;
        data.mode = 'cash';
        data.currency = 952;
        data.lang = localStorage.getItem('lang');
        data.type = data.type || '';
        data.agentplatform = agentid;
        data.version = 7;
        data.hash = Md5.hashStr(data.provider + data.payref + data.amount + apikey).toString();

        let url = urls[service];

        if ((category === 'cashin' || category === 'cashout') && great !== 'afrikpay') {
            url = 'account/recharge/v2/';
        }

        if (((data.type === 'cashout' || data.type === 'cashin') && data.mode === 'cash') || data.type === 'recharge') {
            url = 'account/agent/recharge/v2/';
        }

        return this.http.post(baseURL + url, data);
    }

    agentTransfer(data) {
        this.resetAutoLogout();
        data.agentplatform = agentid;
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;
        data.hash = Md5.hashStr(data.agentreceiver + data.amount + apikey).toString();

        return this.http.post(baseURL + 'account/agent/transfert/', data);
    }

    reset(data: any) {
        this.resetAutoLogout();
        data.lang = localStorage.getItem('lang');
        data.agentid = data.agentid || agentid;
        data.agentpwd = data.agentpwd || agentpwd;
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;
        return this.http.post(baseURL + ops[data.op], data);
    }

    getAgent(data: any) {
        data.lang = localStorage.getItem('lang');
        data.agentpwd = agentpwd;
        data.agentid = agentid;
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;
        return this.http.post(baseURL + 'account/getagent/', data);
    }

    getOperators(product: string, mode: string, category: string) {
        this.resetAutoLogout();
        const lang = localStorage.getItem('lang');
        var data = category
            ? { mode, category, lang, identify: this.identify, terminatype: this.terminaltype }
            : { mode, lang, identify: this.identify, terminatype: this.terminaltype };
        const params = new HttpParams({ fromObject: data });
        return this.http.get(baseURL + endpoints[product], { params });
    }

    getData(biller: string, reference: string, agent: string, action: string, form = null, amount = 0, id = null) {
        this.resetAutoLogout();
        var hash = Md5.hashStr(biller + reference + action + apikey).toString();
        return this.http.post(baseURL + 'bill/action/', {
            biller,
            reference,
            amount,
            agentid,
            form,
            hash,
            action,
            terminaltype: this.terminaltype,
            identify: this.identify,
            agent,
        });
    }

    getFees(data: any) {
        this.resetAutoLogout();
        data.currency = 952;
        data.agentid = data.agentid || agentid;
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;
        const params = new HttpParams({ fromObject: data });
        return this.http.get(baseURL + 'getfees/', { params });
    }

    getAllAgent() {
        this.resetAutoLogout();
        const lang = localStorage.getItem('lang');
        const params = new HttpParams({
            fromObject: { lang, terminaltype: this.terminaltype, identify: this.identify },
        });
        return this.http.get(baseURL + 'account/agent/get/', { params: params });
    }

    getAgentName(data) {
        this.resetAutoLogout();
        data.agentplatform = agentid;
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;
        data.hash = Md5.hashStr(data.agentid + apikey).toString();
        return this.http.post(baseURL + 'account/agent/name/v2/', data);
    }

    getBalance(data: any) {
        this.resetAutoLogout();
        data.agentid = data.agentid || agentid;
        data.password = agentpwd;
        data.lang = localStorage.getItem('lang');
        data.agentplatform = agentid;
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;

        if (data.mode === 'agent') {
            data.hash = Md5.hashStr(data.agentid + apikey).toString();
        }

        return this.http.post(baseURL + balances[data.mode], data);
    }

    getHistory(data: any) {
        this.resetAutoLogout();
        data.agentid = data.agentid || agentid;
        data.lang = this.langage;
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;

        if (data.mode === 'agent') {
            data.hash = Md5.hashStr(data.agentid + apikey).toString();
            data.agentplatform = agentid;
        } else {
            data.password = agentpwd;
        }

        return this.http.post(baseURL + historyType[data.mode], data);
    }

    getRecipient(data: any) {
        this.resetAutoLogout();
        data.lang = localStorage.getItem('lang');
        data.hash = Md5.hashStr(data.agentid + apikey).toString();
        data.agentplatform = agentid;
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;
        return this.http.post(baseURL + 'money/getname/', data);
    }

    export(data: any) {
        this.resetAutoLogout();
        data.lang = localStorage.getItem('lang');
        data.hash = Md5.hashStr(data.agentid + apikey).toString();
        data.terminaltype = this.terminaltype;
        data.identify = this.identify;
        data.agentplatform = agentid;
        return this.http.post(baseURL + 'account/agent/transaction/export/', data);
    }

    changeLang(lang: string, agentid: string) {
        this.autoLogout();
        return this.http.post(baseURL + 'account/agent/modifylang/', {
            lang,
            agentid,
            terminaltype: this.terminaltype,
            identify: this.identify,
        });
    }

    getStatus(id: number) {
        this.resetAutoLogout();
        let obj: { [k: string]: any } = {};
        obj.transactionid = id;
        obj.lang = localStorage.getItem('lang');
        obj.agentid = agentid;
        obj.agentpwd = agentpwd;
        obj.identify = this.identify;
        obj.terminaltype = this.terminaltype;
        obj.agentplatform = agentid;

        return this.http.post(baseURL + 'account/transaction/status/', obj);
    }

    print(url: string, id: string, slug: string, category: string) {
        this.resetAutoLogout();
        var newwindow = window.open(url + '?reference=' + id + '&service=' + slug + '&category=' + category);
        newwindow.focus();
        newwindow.onblur = function () {
            newwindow.close();
        };
        return;
    }

    getPayment(client: string) {
        this.resetAutoLogout();
        let obj: { [k: string]: any } = {};
        obj.client = client;
        obj.agentid = agentid;
        obj.agentpwd = agentpwd;
        obj.lang = localStorage.getItem('lang');
        obj.identify = this.identify;
        obj.terminaltype = this.terminaltype;
        obj.agentplatform = agentid;
        return this.http.post(baseURL + 'account/payment/get/', obj);
    }

    setPaymentStatus(client: string, transactionid: string, status: string, pin: string) {
        this.resetAutoLogout();
        let obj: { [k: string]: any } = {};
        obj.client = client;
        obj.transactionid = transactionid;
        obj.status = status;
        obj.lang = localStorage.getItem('lang');
        obj.pin = pin;
        obj.identify = this.identify;
        obj.terminaltype = this.terminaltype;
        obj.agentplatform = agentid;
        return this.http.post(baseURL + 'account/payment/confirm/', obj);
    }

    confirmWallet(otp: string, transactionid: string, mode: string) {
        this.resetAutoLogout();
        let data: { [k: string]: any } = {};
        data.agentid = data.agentid || agentid;
        data.otp = otp;
        data.lang = localStorage.getItem('lang');
        data.transactionid = transactionid;
        data.identify = this.identify;
        data.agentplatform = agentid;
        data.terminaltype = this.terminaltype;
        return this.http.post(baseURL + 'account/' + mode + '/wallet/confirm/', data);
    }

    addWallet(data: any, mode: string) {
        this.resetAutoLogout();
        data.agentid = data.agentid || agentid;
        data.password = agentpwd;
        data.lang = localStorage.getItem('lang');
        data.identify = this.identify;
        data.agentplatform = agentid;
        data.terminaltype = this.terminaltype;

        return this.http.post(baseURL + 'account/' + mode + '/wallet/add/', data);
    }

    getWallets(id: string, mode: string) {
        this.resetAutoLogout();
        let obj: { [k: string]: any } = {};
        if (mode === 'client') {
            obj.client = id;
        } else {
            obj.agentid = id;
        }
        obj.lang = localStorage.getItem('lang');
        obj.identify = this.identify;
        obj.terminaltype = this.terminaltype;
        obj.agentplatform = agentid;

        return this.http.post(baseURL + 'account/' + mode + '/wallet/get/', obj);
    }

    customRequest(url: string, data: any) {
        this.resetAutoLogout();
        data.identify = this.identify;
        data.agentplatform = agentid;
        data.terminaltype = this.terminaltype;
        const params = new HttpParams({ fromObject: data });
        return this.http.get(url, { params: params });
    }

    generateOtp(email: string) {
        const data = {
            identity: '686768678686',
            email,
            segment: 'portal_validate_trx',
            message: 'OTP Login Code ({otp})',
        };
        return this.http.post(baseURL + 'messaging/otp/generate/', data);
    }

    verifyOtp(otp: string) {
        const data = {
            segment: 'portal_validate_trx',
            identity: '686768678686',
            otp,
        };
        return this.http.post(baseURL + 'messaging/otp/confirm/', data);
    }

    transferCommission(data: any) {
        data.agentplatform = agentid;
        return this.http.post(baseURL + 'account/commission/transfer/', data);
    }
}
