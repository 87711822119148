<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}" >
    <app-header></app-header>


    <div *ngIf="loader" class="d-flex justify-content-center align-content-center">
        <img  src="/assets/img/loader.gif" width="50"  />
    </div>
  
    <iframe width="100%" target="_self"  (load)="hideLoader()" height="100%" frameBorder="0" [src]="urlSafe" id="container"></iframe>
</div>
