// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    // production: true,
    // targetAPI : 'https://api.afrikpay.com/api/',
    production: false,
    targetAPI: 'https://sandbox.api.afrikpay.com/api/',
    agentid: '3769066845956948',
    agentpwd: 'acdb749791840d342377888725f746cb',
    apikey: '3260b2e84cf10d5f933baf2302752693',
    url: 'https://sandbox.oss.afrikpay.com',
    id: 'oss.afrikpay.org',
    accepturl: 'https://sandbox.oss.afrikpay.org/payment/web/success',
    cancelurl: 'https://sandbox.oss.afrikpay.org/payment/web/failed',
    printurl: 'https://invoice.afrikpay.org/print/',
    storage: 'home/oss/demo',
    downloadBill: 'https://sandbox.invoice.afrikpay.com/print',
    // targetAPI: 'http://10.200.115.2:10011/',
    // sandboxApi: 'https://sandbox.api.afrikpay.com/api',
    // agentid : '3716604902759252',
    // agentpwd : 'f3188e720443c003657ef333d8748d3c',
    // apikey : '9714714121b488e03625673186baee8e',
    // url : 'http://10.200.115.2:10010',
    // id : '10.200.115.2:10010',
    // accepturl : 'http://10.200.115.2:10010/payment/web/success',
    // cancelurl : 'https://10.200.115.2:10010/payment/web/failed',
    // printurl : "https://10.200.115.2:10010/print/",
    // storage : 'home/oss'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
