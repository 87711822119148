<div [ngClass]="{ 'pc-view': !isMobile(), 'mobile-view': isMobile() }">
    <app-header></app-header>

    <div class="d-flex justify-content-between align-items-center header-service pt-4 pb-4 pr-2 pl-2">
        <div class="">
            <p class="display-5 text-white">{{ "transfer_commission" | translate }}</p>
            <p class="display-5 text-white">{{ "current_amount" | translate }} : XAF {{ mainBalance }}</p>
        </div>
        <span class="mdi mdi-account-arrow-right text-white" style="font-size: 70px"></span>
    </div>

    <div class="pl-8 pt-6">
        <p class="text-primary">{{ "enter_amount_transfer" | translate }}</p>
    </div>
    <div class="pl-6 pr-6 pb-2 d-flex justify-content-center">
        <div class="input-group mb-3 border-bottom border-primary pr-2 pl-2 pt-1 max-w-300">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">XAF</span>
            </div>
            <input
                (focus)="resetFormErrors()"
                type="text"
                [formControl]="amount"
                (input)="onChangeText($event, 'amount')"
                class="form-control text-right font-size-lg no-border"
                placeholder="0"
                aria-label="amount"
                aria-describedby="basic-addon1"
            />
            <div class="input-group-prepend">
                <div class="d-flex flex-row justify-content-end" *ngIf="amountError">
                    <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px"></span>
                </div>
            </div>
        </div>
    </div>

    <div class="reference-zone d-flex flex-column justify-content-center align-items-center mt-7">
        <div class="border-bottom d-flex align-items-center max-w-300">
            <textarea
                (focus)="resetFormErrors()"
                type="text"
                [formControl]="description"
                (input)="onChangeText($event, 'description')"
                class="form-control flex-fill no-border"
                placeholder="Description"
                aria-label="text"
            ></textarea>
        </div>
    </div>

    <div class="button-zone mt-6 d-flex justify-content-center">
        <button class="btn btn-primary btn-sm" (click)="proceed()">
            {{ "transfer" | translate }}
        </button>
    </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalAlert" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ "failed" | translate }}</h5>
            </div>
            <div class="modal-body">
                {{ modalText | translate }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ "cancel" | translate }}</button>
                <button type="button" (click)="toggleModal(0)" class="btn btn-primary-soft" data-dismiss="modal">
                    {{ "retry" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal customModal"
    data-backdrop="static"
    data-keyboard="false"
    id="modalBalanceAlert"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
            </div>
            <div class="modal-body">
                {{ "insufficient_funds" | translate }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ "cancel" | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div
    class="modal customModal"
    data-backdrop="static"
    data-keyboard="false"
    id="modalTransfer"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header" *ngIf="modalType === 'processing'">
                <h5 class="modal-title">{{ modalTitle | translate }}</h5>
            </div>
            <div
                class="modal-body"
                [ngClass]="{
                    'bg-danger text-white': modalType === 'danger',
                    'bg-warning  text-white': modalType === 'warning',
                    'bg-success  text-white': modalType === 'success'
                }"
            >
                <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
                    <div>
                        <span
                            *ngIf="modalType === 'processing'"
                            class="spinner-border text-primary spinner-border-lg mr-4"
                        ></span>
                        <span
                            *ngIf="modalType === 'danger'"
                            class="mdi mdi-alert text-white mr-4"
                            style="font-size: 2.5em"
                        ></span>
                        <span
                            *ngIf="modalType === 'warning'"
                            class="mdi mdi-alert-circle-outline text-white mr-4"
                            style="font-size: 2.5em"
                        ></span>
                        <span
                            *ngIf="modalType === 'success'"
                            class="mdi mdi-check-all text-white mr-4"
                            style="font-size: 2.5em"
                        ></span>
                    </div>
                    <span [ngClass]="{ 'text-white': !(modalType === 'processing') }" class="text-xs">{{
                        modalText | translate
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="numpad-back" *ngIf="pPadVisible" [ngClass]="{ 'mobile-pt': isMobile() }">
    <div id="numpad-wrap">
        <div class="bg-primary text-white mb-2 p-2 border-tl-r-5 border-tr-r-5">
            {{ "pin_code" | translate }}
        </div>
        <div class="pad-l-10 pad-r-10">
            <input
                id="numpad-display"
                value="{{ pinCode }}"
                class="border-primary text-gray-700"
                type="password"
                readonly=""
            />
        </div>
        <div id="numpad-btns">
            <div *ngFor="let num of numpad" class="numpad-btn" (click)="addDigit(num)">{{ num }}</div>
            <div class="numpad-btn" (click)="deleteDigit()"><img src="/assets/img/backspace.png" /></div>
        </div>

        <div class="buttons-container mt-4 p-2 d-flex justify-content-between border-gray-600 border-top">
            <button class="btn btn-dark-soft text-xs" (click)="togglePad()">
                {{ "cancel" | translate }}
            </button>
            <button class="btn btn-primary text-xs" (click)="transfer()">
                {{ "ok" | translate }}
            </button>
        </div>
    </div>
</div>
