
<div class="row main  p-0 m-0">

  <!-- First Half -->

  <div class="col-md-6 p-0 h-100 d-md-block d-none "
    style="background-image: url('/assets/img/bg-login.jpg'); background-position: center ; background-size: 100% auto; background-repeat: no-repeat; background-color: #165985;">
    <div class="text-white d-md-flex align-items-center h-100  text-center justify-content-center">
      <div class="logoarea pt-5 pb-5">

      </div>
    </div>
  </div>

  <!-- Second Half -->

  <div *ngIf="!isOtpGenerated" class="col-md-6 col-sm-12 col-12 p-0 bg-white h-100 d-block">
    <img src="/assets/img/banner.png" *ngIf="isMobile()" style="position: absolute; top:0; right: 0; width: 100%; height: auto; z-index: 1000;" />

    <div class="d-flex flex-column  h-100  justify-content-center ">

      <div class="reference-zone d-flex flex-column justify-content-center align-items-center mb-8">
        <img src="https://www.afrikpay.com/assets/img/logo.png" class="logo-mobile" *ngIf="isMobile()" />
        <p class="text-gray-800 display-3" *ngIf="!isMobile()"> {{'welcome' | translate}} </p>
      </div>

      <div class="reference-zone d-flex flex-column justify-content-center align-items-center">
        <form [formGroup] = "loginForm">
          <div class=" border-bottom border-primary  d-flex align-items-center max-w-300">
            <div class="input-group-prepend">
              <div class="d-flex flex-row justify-content-start">
                <span class="mdi mdi-account-tie text-primary mr-3" style="font-size: 22px;"></span>
              </div>
            </div>
            <input type="text" formControlName = "phone" (focus)="resetFormErrors()" class="form-control flex-fill no-border" placeholder="{{'username' | translate}}"
              aria-label="Text input with dropdown button">
            <div class="input-group-prepend">
              <div class="d-flex flex-row justify-content-end" *ngIf="phoneError">
                <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
              </div>
            </div>
          </div>
          <div class="border-bottom border-primary d-flex align-items-center mt-6 mt-md-9 max-w-300">
            <div class="input-group-prepend">
              <div class="d-flex flex-row justify-content-start">
                <span class="mdi mdi-lock text-primary mr-3" style="font-size: 20px;"></span>
              </div>
            </div>
            <input type="{{(isPasswordVisible) ? 'text' : 'password'}}"  formControlName = "password"  (focus)="resetFormErrors()" class="form-control flex-fill no-border"
              placeholder="{{'secret_code' | translate}}" aria-label="Text input with dropdown button">
            <div class="input-group-prepend">
              <div class="d-flex flex-row justify-content-end">
                <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"
                  *ngIf="passwordError"></span>
                <span (click)="togglePassword()"
                  [ngClass]="{'mdi-eye-outline' : !isPasswordVisible, 'mdi-eye-off-outline' : isPasswordVisible}"
                  class="mdi mdi-eye-outline text-primary mr-1" style="font-size: 20px;"></span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex justify-content-center  mt-4 pl-3 pr-3">
        <div class="stay-connected pr-6">
          <input type="checkbox" /> <span class="text-xs"> {{'remember_me' | translate}}</span>
        </div>
        <div>
          <span></span>
        </div>
      </div>


      <div class="button-zone mt-6 d-flex justify-content-center">
        <button class="btn btn-primary" (click)="login()">
          {{'sign_in' | translate}}
        </button>

      </div>

    </div>
  </div>
<!--  OTP content-->
  <div *ngIf="isOtpGenerated" class="col-md-6 col-sm-12 col-12 p-0 bg-white h-100 d-block">
    <img src="/assets/img/banner.png" *ngIf="isMobile()" style="position: absolute; top:0; right: 0; width: 100%; height: auto; z-index: 1000;" />

    <div class="d-flex flex-column  h-100  justify-content-center ">

      <div class="reference-zone d-flex flex-column justify-content-center align-items-center mb-8">
        <img src="https://www.afrikpay.com/assets/img/logo.png" class="logo-mobile" *ngIf="isMobile()" />
        <p class="text-gray-800 display-3" *ngIf="!isMobile()"> OTP </p>
      </div>

      <div class="reference-zone d-flex flex-column justify-content-center align-items-center">
        <form [formGroup] = "loginForm">
          <div class=" border-bottom border-primary  d-flex align-items-center max-w-300">
            <div class="input-group-prepend">
              <div class="d-flex flex-row justify-content-start">
                <span class="mdi mdi-account-tie text-primary mr-3" style="font-size: 22px;"></span>
              </div>
            </div>
            <input type="text" formControlName = "otp" maxlength="6"  class="form-control flex-fill no-border"
                   placeholder="OTP Code"
                   aria-label="Text input with dropdown button">
          </div>
        </form>
      </div>
      <div class="button-zone mt-6 d-flex justify-content-center">
        <button class="btn btn-primary" (click)="verifyOtp()">
          Verifier
        </button>

      </div>

    </div>
  </div>
</div>


<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="modalType==='processing'" >
        <h5 class="modal-title">{{modalTitle | translate}}</h5>
      </div>
      <div class="modal-body" [ngClass] = "{'bg-danger text-white': (modalType === 'danger'), 'bg-warning  text-white' : (modalType === 'warning')  }" >
        <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
          <div>
            <span *ngIf="modalType === 'processing'"class="spinner-border text-primary spinner-border-lg mr-4 "></span>
            <span *ngIf="modalType === 'danger'" class="mdi mdi-alert text-white mr-4" style="font-size: 2.5em;"></span>
            <span *ngIf="modalType === 'warning'" class="mdi mdi-alert-circle-outline text-white mr-4" style="font-size: 2.5em;"></span>
          </div>
          <span [ngClass] = " {'text-white' : !(modalType==='processing')} " class="text-xs" >{{modalText | translate}}</span>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="modal" data-backdrop="static" data-keyboard="false" id="modalCountry" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="modalType==='processing'" >
        <h5 class="modal-title"></h5>
      </div>
      <div class="modal-body p-0 border-gray-600 border-bottom"  >
        <div (click) = "selectCountry(item)"   *ngFor="let item of countries" class="container d-flex pr-5 pr-md-2 flex-row justify-content-between align-items-center border-bottom border-gray-600">
          <div class="d-flex flex-row align-items-center">
            <img src="{{item.logourl}}" alt="logo item.name"  style="width: 2em; height: auto; margin-right: 5px;" />
            <span class="text-xs"> {{getName(item)}} </span>
          </div>
          <span class="text-xs" >(+{{item.code}})</span>
        </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">{{'cancel'| translate}}</button>
      </div>
    </div>
  </div>
</div>
