<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">

  <app-header></app-header>

  <div class="d-flex flex-row justify-content-center align-items-center p-5  header ">
    <div class="avatar d-flex justify-content-center align-items-center">
      <span class="mdi mdi-account-circle text-white mr-3" style="font-size: 90px;"></span>
    </div>
    <div class="user-info pl-5">
      <span class="text-white d-block">{{user.agent.agentname}}</span>
      <span class="text-white text-xs d-block">{{user.agent.idagent}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-3 border-bottom border-gray-600">
      <a routerLink="/home/account/reset/secret_code"
        class="d-flex flex-row align-items-center justify-content-start  text-decoration-none not-link pl-5">
        <span class="mdi mdi-lock-reset text-primary" style="font-size: 24px;"></span>
        <span class="text-xs  align-middle text-dark pl-3"> {{ 'change_secret_code' | translate }} </span>
      </a>
    </div>
    <div class="col-12 p-1 pl-3 border-bottom border-gray-600">
      <a class="d-flex flex-row align-items-center justify-content-between  text-decoration-none not-link pl-5 pr-5">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <span class="mdi mdi-translate text-primary" style="font-size: 24px;"></span>
          <span class="text-xs  align-middle text-dark pl-3"> {{ 'language' | translate }} </span>
        </div>
        <div class="d-flex flex-row justify-content-center align-items-center pt-2">
          <span class="text-xs radio mr-3">
            <label>
              <input type="radio" value='en' name="lang" [checked]="lang === 'en'" (click)="setLang('en')" />
              <span class="rdo"></span>
              <span>EN</span>
            </label>
          </span>
          <span class="text-xs radio">
            <label><input type="radio" value='fr' name="lang" [checked]="lang === 'fr'" (click)="setLang('fr')" />
              <span class="rdo"></span>
              <span>FR</span>
            </label>
          </span>
        </div>
      </a>
    </div>
    <div class="col-12 p-3 border-bottom border-gray-600">
      <a (click)="logout()" style="cursor: pointer;"
        class="d-flex flex-row align-items-center justify-content-start  text-decoration-none not-link pl-5">
        <span class="mdi mdi-logout text-primary" style="font-size: 24px;"></span>
        <span class="text-xs  align-middle text-dark pl-3"> {{ 'sign_out' | translate }} </span>
      </a>
    </div>
  </div>

  <div class="view-side-form">
    <router-outlet></router-outlet>
  </div>

  <div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalLogout" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"> {{'sign_out'| translate}} </h5>
        </div>
        <div class="modal-body">
          {{'do_sign_out'| translate}}
        </div>

        <div class="modal-footer">
          <button type="button" (click)="goToLogout()" class="btn btn-primary btn-sm"
            data-dismiss="modal">{{'yes'| translate}}</button>
          <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">{{'no'| translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <app-bottom-tab></app-bottom-tab>
</div>


