<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">
  <app-header></app-header>

  <div class="d-flex justify-content-center align-items-center p-5  header ">
    <span class="text-white d-block  text-center"> {{ 'more_about' | translate }} </span>
  </div>
  <div class="row">
    <div class="col-12 p-1 pl-3 pr-4 border-bottom border-gray-600" *ngFor="let item of list" (click)="select(item)">
      <a class="d-flex flex-row align-items-center justify-content-between  text-decoration-none not-link p-2 pl-5 "
        style="cursor: pointer;">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <span class=" align-middle text-dark pl-3 ">{{item.title | translate}}</span>
        </div>
        <span class="mdi mdi-chevron-right text-primary" style="font-size: 24px;"></span>
      </a>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between align-items-center mt-4 p-4">
    <a href="{{fcbk}}" target="_blank" >
      <span class="mdi mdi-facebook-box text-primary" style="font-size: 40px; cursor: pointer;"></span>
    </a>
    <a href="{{lnkdin}}" target="_blank">
      <span class="mdi mdi-linkedin-box text-primary" style="font-size: 40px; cursor: pointer;"></span>
    </a>
    <a href="{{twitter}}" target="_blank">
      <span class="mdi mdi-twitter-box text-primary" style="font-size: 40px; cursor: pointer;"></span>
    </a>
  </div>
</div>
<app-bottom-tab></app-bottom-tab>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalDetails" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content" *ngIf="selected">
      <div class="modal-header bg-primary">
        <h5 class="modal-title text-white">{{ selected.title | translate}}</h5>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column">
          <p class="text-justify"> {{ selected.content | translate}} </p>
        </div>

        <a class="text-primary text-decoration-none" href="{{selected.link}}" *ngIf="selected.link" target="_blank"
          style="cursor: pointer !important;">
          > {{'link'|translate}} < </a> </div> <div class="modal-footer d-flex justify-content-between">
            <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">{{'cancel'| translate}}</button>
      </div>
    </div>
  </div>
</div>
