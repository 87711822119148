import { Component, OnInit } from '@angular/core';
import { HelperService } from '../helper.service';
import { HttpService } from '../http.service';
import { StoreService } from '../store.service';
import {FormControl} from '@angular/forms';
// import slide in/out animation
import { slideInOutAnimation2 } from '../_animations/index';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {environment} from "../../environments/environment"
declare var $: any;

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation2],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation2]': '' }
})
export class PrinterComponent implements OnInit {

  service : any;
  product : any;
  billid: FormControl;
  billidError = false;
  lang : string;

  constructor(private helper: HelperService, private store: StoreService,
    private http: HttpService, private translate: TranslateService, private router: Router) { }

  ngOnInit() {

    this.product = window.history.state.product;
    this.service = window.history.state.service;


    if(!this.product || !this.service) this.router.navigateByUrl('/home');

    this.lang = localStorage.getItem('lang');
    this.billid = new FormControl("");
  }

  print() {
    let bool = true;

    var billid = this.billid.value;
    let regex = this.service.accountregex.toString();
    regex = regex.slice(1);
    regex = regex.substring(0, regex.length - 1);
    regex = new RegExp(regex, '');
    var isMatching = billid.match(regex) ? true : false;
    if (!isMatching) {
      bool = bool && false;
      this.billidError = true;
    }


    if(bool) {
      this.http.print(environment.printurl, billid, this.service.slug, this.product.category);
    }
  }


  resetFormErrors() {
    this.billidError = false;
  }


  getRefLabel(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        return service.translations[this.lang].reflabel || service.reflabel;
      }
    }
    return service.reflabel;
  }

  getName(service: any) {
    if(!service) return null;

    let name = service.name;

    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(this.lang)) {
        name = service.translations[this.lang].name || service.name;
      }
    }
    return name ? name.length > 0 ? name : null : null;
  }


  isMobile(){
    return this.helper.isMobile();
  }

}
