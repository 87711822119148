<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">
    <app-header></app-header>

    <div
      class="d-flex justify-content-between align-items-center  header-service pt-4 pb-4 pr-2 pl-2">
      <div class="">
        <p class="display-5 text-white">{{'history' | translate }}</p>
      </div>
      <div>
        <button class="btn btn-white" [disabled]="!(history?.length > 0)" [routerLink]="['/home/history/export']">
          <span class="mdi mdi-calendar-export mr-2"></span>{{'export' | translate}}
        </button>
      </div>
      </div>

      <div class="row" *ngIf="history?.length > 0">
        <div *ngFor="let item of history" class="col-12 d-flex flex-row align-items-center p-1 pl-4 pr-6  border-bottom border-gray-600">
          <div class="icon_container p-1">
            <span *ngIf="!(item.agentflow)" class="mdi mdi-menu-up text-danger" style="font-size: 30px;"></span>
            <span *ngIf="(item.agentflow)" class="mdi mdi-menu-down text-success" style="font-size: 30px;"></span>
          </div>
          <div class="body d-flex flex-row justify-content-between align-items-center flex-fill">
            <div class="d-flex flex-column justify-content-start">
              <span class="text-xs text-gray-800"> {{formatText(item.billid || item.payref || item.client )}} </span>
              <span class="font-weight-bold text-xs mt-3">{{item.description}}</span>
              <span class="font-weight-bold text-xs">{{getDate(item.date)}}</span>
            </div>
            <div class="d-flex flex-column justify-content-end align-middle ">
              <span class="text-xs font-weight-bold text-right">XAF {{item.amount | number}}</span>
              <!-- <span class="text-xs text-right text-gray-700"> {{'commission' | translate}} : {{trunc(item.agentfees)}}</span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2">
        <span *ngIf="loading"  class="spinner-border text-primary spinner-border-sm"></span>
        <button  (click) ="updateHistory()"  *ngIf="moreBtn && history?.length > 0" class="btn btn-small btn-primary-soft">{{'show_more'|translate}}</button>
      </div>


      <div class="view-side">
        <router-outlet></router-outlet>
      </div>

  </div>

  <div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalHistory" tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header" *ngIf="modalType==='processing'">
          <h5 class="modal-title">{{modalTitle | translate}}</h5>
        </div>
        <div class="modal-body"
          [ngClass]="{'bg-danger text-white': (modalType === 'danger'), 'bg-warning  text-white' : (modalType === 'warning')  }">
          <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
            <div>
              <span *ngIf="modalType === 'processing'" class="spinner-border text-primary spinner-border-lg mr-4 "></span>
              <span *ngIf="modalType === 'danger'" class="mdi mdi-alert text-white mr-4" style="font-size: 2.5em;"></span>
              <span *ngIf="modalType === 'warning'" class="mdi mdi-alert-circle-outline text-white mr-4"
                style="font-size: 2.5em;"></span>
            </div>
            <span [ngClass]=" {'text-white' : !(modalType==='processing')} "
              class="text-xs">{{modalText | translate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
