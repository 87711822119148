<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">
  <app-header></app-header>

  <div class="d-flex justify-content-between align-items-center  header-service pt-4 pb-4 pr-2 pl-2">
    <div class="">
      <p class="display-5 text-white">{{ 'export_history'| translate }}</p>
    </div>
    <span class="mdi mdi-calendar-export text-white" style="font-size: 70px;"></span>
  </div>

  <div class="reference-zone d-flex flex-column justify-content-center align-items-center mt-4 pb-2">
    <div>
      <label class="custom-label mb-0">{{ 'start_date' | translate }}</label>
      <div class="border-bottom border-primary pt-0 mt-0 d-flex align-items-center max-w-300">
        <input (focus)="resetError()" type="date" [formControl]="startDate" name="startDate" class="form-control flex-fill no-border"
          placeholder="{{ 'start_date | translate' }}" aria-label="text">
        <div class="input-group-prepend">
          <div class="d-flex flex-row justify-content-end" *ngIf="startDateError">
            <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <label class="custom-label mb-0">{{ 'end_date' | translate }}</label>
      <div class="border-bottom border-primary pt-0 mt-0 d-flex align-items-center max-w-300">
        <input (focus)="resetError()" type="date" [formControl]="endDate"
          name="end_date" class="form-control flex-fill no-border"
          placeholder="{{ 'end_date | translate' }}" aria-label="text">
        <div class="input-group-prepend">
          <div class="d-flex flex-row justify-content-end" *ngIf="endDateError">
            <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <label class="custom-label mb-0">{{ 'reference' | translate }}</label>
      <div class="border-bottom border-primary align-items-center pt-0 mt-0 d-flex max-w-300">
        <div class="input-group-prepend">
          <div class="d-flex flex-row justify-content-start">
            <span class="mdi mdi-ticket-confirmation text-primary mr-3" style="font-size: 20px;"></span>
          </div>
        </div>
        <input (focus)="resetError()" type="text" [formControl]="reference"  name="reference"
          class="form-control flex-fill no-border" placeholder="{{ 'reference' | translate }}"
          aria-label="text">
        <div class="input-group-prepend">
          <div class="d-flex flex-row justify-content-end" *ngIf="referenceError">
            <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <label class="custom-label mb-0">{{ 'description' | translate }}</label>
      <div class="border-bottom border-primary pt-0 mt-0 d-flex align-items-center max-w-300">
        <div class="input-group-prepend">
          <div class="d-flex flex-row justify-content-start">
            <span class="mdi mdi-ticket-confirmation text-primary mr-3" style="font-size: 20px;"></span>
          </div>
        </div>
        <input (focus)="resetError()" type="text" [formControl]="description"  name="description"
          class="form-control flex-fill no-border" placeholder="{{ 'description' | translate }}"
          aria-label="text">
        <div class="input-group-prepend">
          <div class="d-flex flex-row justify-content-end" *ngIf="descriptionError">
            <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
          </div>
        </div>
      </div>
    </div>


    <div class="button-zone mt-6 d-flex flex-column justify-content-center">
      <button class="btn btn-primary btn-sm" (click)="export()" >
        {{ 'export' | translate }}
      </button>
    </div>


  </div>

</div>



<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalExportAlert" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{modalTitle | translate}}</h5>
      </div>
      <div class="modal-body">
        {{modalText | translate}}
      </div>
      <div class="modal-footer" >
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'cancel' | translate}}</button>
        <button *ngIf="retry" (click) = "export()" type="button" class="btn btn-primary-soft" data-dismiss="modal">{{'retry' | translate}}</button>
        <button *ngIf="!retry" [routerLink] = "['/home']" type="button" class="btn btn-primary" data-dismiss="modal">{{'Ok' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalExport" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="modalType==='processing'">
        <h5 class="modal-title">{{modalTitle | translate}}</h5>
      </div>
      <div class="modal-body"
        [ngClass]="{'bg-danger text-white': (modalType === 'danger'), 'bg-warning  text-white' : (modalType === 'warning') , 'bg-success  text-white' : (modalType === 'success') }">
        <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
          <div>
            <span *ngIf="modalType === 'processing'" class="spinner-border text-primary spinner-border-lg mr-4 "></span>
            <span *ngIf="modalType === 'danger'" class="mdi mdi-alert text-white mr-4" style="font-size: 2.5em;"></span>
            <span *ngIf="modalType === 'warning'" class="mdi mdi-alert-circle-outline text-white mr-4"
              style="font-size: 2.5em;"></span>
              <span *ngIf="modalType === 'success'" class="mdi mdi-check-all text-white mr-4" style="font-size: 2.5em;"></span>
          </div>
          <span [ngClass]=" {'text-white' : !(modalType==='processing')} "
            class="text-xs">{{modalText | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
