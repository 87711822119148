import { Component, OnInit } from '@angular/core';
import { HelperService } from '../helper.service';
import { StoreService } from '../store.service';
import { HttpService } from '../http.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { User } from '../classes';
declare var $ : any;

@Component({
  selector: 'app-home-agent',
  templateUrl: './home-agent.component.html',
  styleUrls: ['./home-agent.component.css']
})

export class HomeAgentComponent implements OnInit {

  products = {
    airtime: {
      title: 'airtime_internet',
      second_title: 'buy_airtime',
      item_header: 'recharge',
      api: 'airtime',
      need_pin: true,
      need_code: false,
      payment_view: 'payment',
      mode: 'agent',
      zone : 'agent',
      category: 'airtime',
      withname : false,
      fees_agent : true
    },
    momo: {
      title: 'mobile_money',
      second_title: 'mobile_money',
      item_header: 'transaction',
      api: 'recharge',
      need_pin : true,
      payment_view : 'payment',
      mode : 'agent',
      zone : 'agent',
      category: null,
      recharge : false,
      withname : true,
      fees_agent : true,
      operations : true
    },
    recharge: {
      title: 'topup_with',
      second_title: 'topup_account',
      item_header: 'topup_account_with',
      api: 'recharge',
      need_pin: false,
      need_code: true,
      payment_view: 'payment',
      mode: 'client',
      zone : 'agent',
      category: 'cashout',
      recharge : true,
      withname : false,
      fees_agent : false
    },
    school: {
      title: 'tuition_fees',
      second_title: 'tuition_fees',
      item_header: 'pay_fees_for',
      api: 'bill',
      need_pin: true,
      payment_view: 'paybill',
      mode: 'agent',
      zone : 'agent',
      category: 'school',
      withname : false
    },
    printing: {
      title: 'printing',
      second_title: 'print_receipt',
      item_header: '',
      need_pin: false,
      payment_view: 'printer',
      api: 'bill',
      mode: 'client',
      zone: 'agent',
      category: 'all'
    },
    bill: {
      title: 'pay_bill',
      second_title: 'bill_payment_subs',
      item_header: '',
      api: 'bill',
      need_pin: true,
      need_code: false,
      payment_view: 'paybill',
      mode: 'agent',
      zone: 'agent',
      category: 'bill'
    },
    service: {
      title: 'services',
      second_title: 'services',
      item_header: 'service_of',
      api: 'bill',
      need_pin: true,
      payment_view: 'paybill',
      mode: 'agent',
      zone: 'agent',
      category: 'service',
      withname : false
    }
  };

  modalTitle : string;
  modalText : string;
  modalType : string;
  product : any;

  padVisible = false;
  numpad = [];
  wallets = [];
  mainDeposit = null;
  mainBalance = null;
  user : User;


  operations = [];
  operation = null;

  loading : boolean;
  request : string;
  services : any;
  service : any;

  constructor(private helper : HelperService, private store : StoreService,
    private http: HttpService, private translate : TranslateService, private router : Router) { }

  ngOnInit() {
    this.user = this.store.getUser();
    console.log(this.user)
    window.setInterval(() => {this.http.autoLogout();}, 1000);

  }

  isMobile() {
    return this.helper.isMobile();
  }

  checkClass(elt){
    if(!this.product) return false;
    return !this.helper.isMobile() && (this.product.title === elt.title);
  }

  goTo(route : string, data : JSON) : void {
    this.router.navigateByUrl(route,{state : {product :data}});
  }

  getName(service: any) {
    if (service.hasOwnProperty('translations')) {
      if (service.translations.hasOwnProperty(localStorage.getItem('lang'))) {
        return service.translations[localStorage.getItem('lang')].name || service.name;
      }
    }
    return service.name;
  }

  goToService(data){
    this.product = data;
    this.loading = true;
    this.request = 'operators';
    this.services = null;
    const product = data;

    if(this.helper.isMobile()) this.goTo('home/services', data);

    if(!this.helper.isMobile()){

      if(this.product.category === 'cashout') {

        var id = this.user.agent.idagent;

        this.http.getWallets(id, this.product.zone).subscribe( (response : any) => {
          if(response){
            if(response.code === 200 ) {
              this.wallets = response.result;

              this.http.getOperators(product.api, product.mode, product.category).subscribe((response : any) => {
                this.services = response.result;
                this.loading = false;
                this.request = '';

                for(let i = 0; i < this.services.length; i++) {
                  for (const method of this.wallets) {
                    if(this.services[i].slug === method.provider) {
                      this.services[i].wallet = method;
                      break;
                    }
                  }
                }
              })
            }
          }

        })

      } else {

        this.http.getOperators(product.api, product.mode, product.category).subscribe((response : any) => {
          this.services = response.result;
          this.loading = false;
          this.request = '';
        })

      }
    }
  }

  chooseOperation(operation : any){
    $('#modalList').modal('hide');
    this.router.navigateByUrl('home/' + this.product.payment_view , {state : {product : this.product, service : this.service, operation : operation}});
  }

  isPaypalOrCard(s:any){
    return (s.type === 'paypal' || s.type === 'card');
  }


  goToPayment(service : any) : void {

    this.service = service;

    if(service.comingsoon){
      $('#modalUnavailable').modal('show');
      setTimeout(() => {
        $('#modalUnavailable').modal('hide');
      }, 2500);
      return;
    }


    // if(service.type === 'paypal' && this.product.category === 'cashout' ) {
    //   if(service.wallet) {
    //     this.router.navigateByUrl('home/payment/async' , {state : {product : this.product, service : service}});
    //   } else {
    //     this.router.navigateByUrl('home/wallet/add' , {state : {product : this.product, service : service}});
    //   }
    //   return;
    // }

    if(!service.wallet && this.product.category === 'cashout') {
      this.router.navigateByUrl('home/wallet/add' , {state : {product : this.product, service : service}});
      return;
    }

    if(this.product.operations) {
      this.operations = service.paymentEndpoints;
      $('#modalList').modal('show');
      return;
    }

    if(this.isPaypalOrCard(service) && this.product.category === 'cashout' ) {
      this.router.navigateByUrl('home/payment/async' , {state : {product : this.product, service : service}});
    } else {
      this.router.navigateByUrl('home/'+this.product.payment_view , {state : {product : this.product, service : service}});
    }


  }


  setModal(type : string, text : string, title : string) {

    this.modalType = type;

    switch (type) {
      case 'processing' : {
        this.modalTitle = title;
        this.translate.get('please_wait').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'warning' : {
        this.modalTitle = '';
        this.translate.get('failed').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'danger' : {
        this.modalTitle = '';
        this.modalText = text;
        break;
      }

      default : break;
    }

  }

  dismissModal(timer : number, dest : string){
    setTimeout(() => {
      $('#modal').modal('toggle');
      (dest) ? this.router.navigateByUrl(dest) : null;
    }, timer);
  }


  balance() {
		let data = {
      phone : this.user.phonecompte,
      mode : 'agent',
      agentid : this.user.agent.idagent,
      agentpwd : this.user.agent.password
    };

		this.setModal('processing', 'please_wait', 'checking_balance');
    $('#modal').modal('toggle');



		this.http.getBalance(data).subscribe((response : any) => {
			if (response) {
				if (response.code === 200) {
					this.dismissModal(1,null);
          this.mainBalance = response.result.mainbalance;
          this.mainDeposit = response.result.maindeposit;
				} else {
					this.setModal('danger', response.message || '', '');
          this.dismissModal(2500, null);
				}
			} else {
				this.setModal('warning', 'failed', '');
        this.dismissModal(2500, null);
			}
		});
	}

}
