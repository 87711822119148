import { Component, OnInit } from '@angular/core';
// import slide in/out animation
import { slideInOutAnimation , fadeInAnimation} from '../_animations/index';
import { HelperService } from '../helper.service';
import { StoreService } from '../store.service';
import { HttpService } from '../http.service';
import { TranslateService } from '@ngx-translate/core';
import  * as moment from 'moment';
import 'moment/min/locales';
import { Router } from '@angular/router';
import { User } from '../classes';
declare var $ : any;

@Component({
  selector: 'app-history-agent',
  templateUrl: './history-agent.component.html',
  styleUrls: ['./history-agent.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation, fadeInAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' , '[@fadeInAnimation]': '' }
})
export class HistoryAgentComponent implements OnInit {

  modalText : string;
  modalTitle : string;
  modalType : string;
  history = [];
  user : User;
  lang : string;
  moreBtn = true;
  loading = false;
  page : number;

  constructor(private helper : HelperService, private store : StoreService, private http : HttpService,
    private translate : TranslateService, private router : Router) { }

  ngOnInit() {
    this.user = this.store.getUser();
    this.lang = localStorage.getItem("lang");
    this.page = 1;
    this.getHistory();
  }

  getHistory(){

    let data = {
      phone : this.user.phonecompte,
      mode : 'agent',
      offset : this.page,
      agentid : this.user.agent.idagent
    }

    this.setModal('processing', 'please_wait', 'loading_transactions');
    $('#modalHistory').modal('show');

    this.http.getHistory(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200){
          this.history = response.result;
          this.page++;
          this.dismissModal(1, null);
        }else {
          this.setModal('danger', response.message || '', '');
          this.dismissModal(2500, null);
        }
      }else {
        this.setModal('warning', 'failed', '');
        this.dismissModal(2500, null);
      }
    },
    (error : any) => {

      if(error){
        this.setModal('warning', 'failed', '');
        this.dismissModal(2500, null);
      }

    })

  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;

    switch (type) {
      case 'processing' : {
        this.modalTitle = title;
        this.translate.get('please_wait').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'warning' : {
        this.modalTitle = '';
        this.translate.get('failed').subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }

      case 'danger' : {
        this.modalTitle = '';
        this.modalText = text;
        break;
      }

      default : break;
    }

  }

  dismissModal(timer : number, dest : string){
    setTimeout(() => {
      $('#modalHistory').modal('toggle');
      (dest) ? this.router.navigateByUrl(dest) : null;
    }, timer);
  }

  isMobile(){
    return this.helper.isMobile();
  }

  getDate(date : string){
    return moment(date).locale(this.lang).format('LLL');
  }

  formatText(value) {
		// var output = '';
		// var str = value || 'XXXXXXXXX';

		// var rest = str.length % 3;
		// var part = Math.trunc(str.length / 3);

		// if (str.length > 3) {
		// 	output += str.substring(0, rest);
		// 	str = str.slice(rest);

		// 	for (var i = 0; i < part; i++) {
		// 		output += ' ';
		// 		output += str.substring(0, 3);
		// 		str = str.slice(3);
		// 	}
		// } else {
		// 	output = str;
		// }

		// return output;
    return value;
  }

  trunc(nbr: number){
    return Math.trunc(nbr);
  }


  updateHistory(){

    let data = {
      phone : this.user.phonecompte,
      mode : 'agent',
      offset : this.page,
      agentid : this.user.agent.idagent
    }

    this.loading = true;
    this.moreBtn = false;

    this.http.getHistory(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200){
          this.history = this.history.concat(response.result);
          this.page++;
          this.loading = false;
          this.moreBtn = true;
        }else {
          this.loading = false;
        }
      }else {
        this.loading = false;
        this.moreBtn = true;
      }
    },
    (error : any) => {

      if(error){
        this.loading = false;
        this.moreBtn = true;
      }

    })

  }

}
