<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">
  <app-header></app-header>
  <div *ngIf="product && service"
    class="d-flex justify-content-between align-items-center  header-service pt-4 pb-4 pr-2 pl-2">
    <div class="">
      <p class="display-5 text-white">{{product.item_header | translate }}</p>
      <p class="display-5 text-white">{{getName(service) | translate | uppercase }}</p>
      <p class="display-5 text-white">{{amount}} XAF</p>
    </div>
    <img src="{{service.logourl}}" width="60" height="60" />
  </div>

  <div *ngIf="loading" class="container d-flex p-5 flex-column justify-content-center align-items-center">
      <span class="spinner-border text-primary spinner-border-sm"></span>
  </div>

  <div class="reference-zone d-flex flex-column justify-content-center align-items-center mt-2 pb-2" *ngIf="!loading">




    <div class="input-container" *ngFor="let input of forms">
      <label class="custom-label mb-0">{{getLabel(input)}}</label><span class="text-danger" *ngIf="input.required">*</span>

      <!-- Default input rendering -->
      <div *ngIf="getModel(input.type) === 'input'">
        <div class="border-bottom border-primary mt-0 d-flex align-items-center max-w-300">
          <div class="input-group-prepend">
            <div class="d-flex flex-row justify-content-start">
              <span class="mdi mdi-code-greater-than text-primary mr-3" style="font-size: 20px;"></span>
            </div>
          </div>
          <input (focus)="resetError(input)" type="{{getType(input.type)}}" (input)="onChangeText($event, input)" name="{{input.name}}" class="form-control flex-fill no-border"
            placeholder="{{input.placeholder}}" aria-label="text">
          <div class="input-group-prepend d-none" id="{{input.name+'Error'}}">
            <div class="d-flex flex-row justify-content-end">
              <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- File input rendering -->
    <div *ngIf="getModel(input.type) === 'file'">
      <div class="border-bottom border-primary mt-0 d-flex align-items-center max-w-300">
        <input  type="file"  (input)="onChangeText($event, input)" name="{{input.name}}" class="form-control flex-fill no-border"
          placeholder="Filer" aria-label="text" accept="image/*, .pdf">
        <div class="input-group-prepend d-none" id="{{input.name+'Error'}}">
          <div class="d-flex flex-row justify-content-end">
            <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
          </div>
        </div>
      </div>
    </div>



      <!-- country input rendering -->
      <div *ngIf="getModel(input.type) === 'country'">
        <div class="d-flex align-items-center max-w-300">
          <select name="{{input.name}}" class="form-control flex-fill"  (input)="onChangeText($event, input)">
            <option>...</option>
            <option  *ngFor="let c of countries"  value="{{c.isoL +'-'+getCurrency(c)+'-'+c.name}}"> {{getName(c)}} </option>
          </select>
        </div>
      </div>

      <!-- country input rendering -->
      <div *ngIf="getModel(input.type) === 'currency'">
        <div class="d-flex align-items-center max-w-300">
          <select name="{{input.name}}" class="form-control flex-fill"  (input)="onChangeText($event, input)"   >
            <option>...</option>
            <option  *ngFor="let c of currencies"  value="{{c.value}}"> {{c.label}} </option>
          </select>
        </div>
      </div>

      <!-- select input rendering -->
      <div *ngIf="getModel(input.type) === 'select'">
        <div class="d-flex align-items-center max-w-300">
          <select name="{{input.name}}" class="form-control flex-fill"  (input)="onChangeText($event, input)"   >
            <option>...</option>
            <option  *ngFor="let c of input.select; let i=index"  [value]="c.value"> {{c.name}} </option>
          </select>
        </div>
      </div>

      <!-- textarea input rendering -->
      <div *ngIf="getModel(input.type) === 'textarea'">
        <div class="d-flex align-items-center max-w-300">
          <textarea class="form-control" rows="5" name="{{input.name}}" class="form-control flex-fill"  (input)="onChangeText($event, input)" ></textarea>
        </div>
      </div>


      <!-- international phone input rendering -->
      <div *ngIf="getModel(input.type) === 'inttel'">
        <div class="border-bottom border-primary mt-0 d-flex align-items-center max-w-300">
          <div class="input-group-prepend">
            <div class="d-flex flex-row justify-content-start">
              <span class="mdi mdi-phone text-primary mr-3" style="font-size: 20px;"></span>
              <span (click)="changeCountry(input)" class="mdi mdi-menu-down text-primary mr-1" style="font-size: 20px;"></span>
              <span (click)="changeCountry(input)" class="" style="font-size: 20px;" id="{{'iso'+input.name}}" iso="cm">+237</span>
            </div>
          </div>
          <input type="tel"  name="{{input.name}}" (focus)="resetError(input)" class="form-control flex-fill no-border" placeholder="{{input.placeholder}}"
            aria-label="Text input with dropdown button" (input)="onChangeText($event, input)">
          <div class="input-group-prepend d-none" id="{{input.name+'Error'}}">
            <div class="d-flex flex-row justify-content-end" >
              <span class="mdi mdi-alert-circle text-danger mr-3" style="font-size: 20px;"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <div class="button-zone mt-6 d-flex flex-column justify-content-center" *ngIf="!loading">
    <button class="btn btn-primary btn-sm" (click)="check()" [disabled]="disable_btn">
      {{'proceed' | translate}}
    </button>
  </div>



  <div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalCountry" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header" *ngIf="modalType==='processing'" >
          <h5 class="modal-title"></h5>
        </div>
        <div class="modal-body p-0 border-gray-600 border-bottom"  >
          <div (click) = "selectCountry(item)" *ngFor="let item of countries" class="container d-flex pr-5 pr-md-2 flex-row justify-content-between align-items-center border-bottom border-gray-600">
            <div class="d-flex flex-row align-items-center">
              <img src="{{item.logourl}}" alt="logo item.name"  style="width: 2em; height: auto; margin-right: 5px;" />
              <span class="text-xs"> {{getName(item)}} </span>
            </div>
            <span class="text-xs" >(+{{item.code}})</span>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">{{'cancel'| translate}}</button>
        </div>
      </div>
    </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalFileAlert" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header bg-danger">
        <h5 class="modal-title text-white">{{'alert' | translate}}</h5>
      </div>
      <div class="modal-body">
        <span>{{file_error | translate}}</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal customModal"   style="overflow-y: scroll;" data-backdrop="static" data-keyboard="false" id="modalDetails" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title text-white">{{'Details' | translate}}</h5>
      </div>
      <div class="modal-body">
        <p *ngIf="!preauthData" class="text-center"> {{'bill_not_found' | translate}} </p>
        <div  *ngIf="preauthData"  class="row border-top border-gray-600">
          <div class="col-12 col-md-12 d-flex flex-column d-block  p-3 ">
            <span  class="text-xs text-muted mb-1"> {{'amount' | translate}} </span>
            <span class="text-primary font-weight-bold"> {{amount}} XAF</span>
          </div>
          <div class="col-12 col-md-12 d-flex flex-column d-block  p-3 ">
            <span  class="text-xs text-muted mb-1"> {{'Reference' | translate}} </span>
            <span class="text-primary font-weight-bold"> {{ billid ? billid : 'NOREF'+ user.phonecompte}}</span>
          </div>
          <div  *ngFor="let ref of preauthData.data"   class="col-12 col-md-12 d-flex flex-column d-block  p-3 ">
            <span  class="text-xs text-muted mb-1"> {{ref.key}} </span>
            <span class="text-primary font-weight-bold"> {{ref.value}}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="execute()" data-dismiss="modal">{{'confirm'| translate}}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'cancel'| translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalBillAlert" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'failed' | translate}}</h5>
      </div>
      <div class="modal-body">
        {{modalText | translate}}
      </div>
      <div class="modal-footer" >
        <button type="button" class="btn btn-primary" (click)="retry()" data-dismiss="modal">{{'retry'| translate}}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalFormPayment" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="modalType==='processing'">
        <h5 class="modal-title">{{modalTitle | translate}}</h5>
      </div>
      <div class="modal-body"
        [ngClass]="{'bg-danger text-white': (modalType === 'danger'), 'bg-warning  text-white' : (modalType === 'warning') , 'bg-success  text-white' : (modalType === 'success') }">
        <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
          <div>
            <span *ngIf="modalType === 'processing'" class="spinner-border text-primary spinner-border-lg mr-4 "></span>
            <span *ngIf="modalType === 'danger'" class="mdi mdi-alert text-white mr-4" style="font-size: 2.5em;"></span>
            <span *ngIf="modalType === 'warning'" class="mdi mdi-alert-circle-outline text-white mr-4"
              style="font-size: 2.5em;"></span>
              <span *ngIf="modalType === 'success'" class="mdi mdi-check-all text-white mr-4" style="font-size: 2.5em;"></span>
          </div>
          <span [ngClass]=" {'text-white' : !(modalType==='processing')} "
            class="text-xs">{{modalText | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalAlert" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'failed' | translate}}</h5>
      </div>
      <div class="modal-body">
        {{modalText | translate}}
      </div>
      <div class="modal-footer" >
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'cancel' | translate}}</button>
        <button type="button" (click) = "retry()"  class="btn btn-primary">{{'retry' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal customModal" data-backdrop="static" data-keyboard="false" id="modalUpload" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="modalType==='failed'">
        <h5 class="modal-title">{{'alert' | translate}}</h5>
      </div>
      <div class="modal-body">
        <div class="container d-flex pr-5 pr-md-3 flex-row justify-content-start align-items-center">
          <div>
            <span *ngIf="modalType === 'processing'" class="spinner-border text-primary spinner-border-lg mr-4 "></span>
          </div>
          <span>{{modalText | translate}}</span>
        </div>
      </div>
      <div class="modal-footer" *ngIf="!(modalType === 'processing')" >
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">{{'ok' | translate}}</button>
      </div>
    </div>
  </div>
</div>


<div id="numpad-back" *ngIf="pPadVisible" [ngClass]="{'mobile-pt': isMobile()}">
  <div id="numpad-wrap">
    <div class="bg-primary text-white mb-2 p-2 border-tl-r-5 border-tr-r-5">
      {{ 'pin_code' | translate}}
    </div>
    <div class="pad-l-10 pad-r-10">
      <input id="numpad-display" value="{{pinCode}}" class="border-primary text-gray-700" type="password" readonly="">
    </div>
    <div id="numpad-btns">
      <div *ngFor="let num of numpad" class="numpad-btn" (click)="addDigit(num)">{{num}}</div>
      <div class="numpad-btn" (click)="deleteDigit()"> <img src="/assets/img/backspace.png" /> </div>
    </div>

    <div class="buttons-container mt-4 p-2 d-flex justify-content-between border-gray-600 border-top">
      <button class="btn btn-dark-soft text-xs" (click)="togglePad()">
        {{'cancel' | translate}}
      </button>
      <button class="btn btn-primary text-xs" (click)="execute()">
        {{'ok' | translate}}
      </button>
    </div>
  </div>
</div>
