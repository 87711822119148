import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../http.service';

@Component({
    selector: 'app-header-home',
    templateUrl: './header-home.component.html',
    styleUrls: ['./header-home.component.css'],
})
export class HeaderHomeComponent implements OnInit {
    constructor(private http: HttpService, private router: Router) {}

    ngOnInit() {
        window.setInterval(() => {
            this.http.autoLogout();
        }, 1000);
    }

    goTo(route: string): void {
        this.router.navigateByUrl(route);
    }
}
