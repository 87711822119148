import { Component, OnInit } from '@angular/core';
import { slideInOutAnimation } from '../_animations';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': ''
  }
})
export class SupportComponent implements OnInit {

  constructor(private helper : HelperService) { }

  ngOnInit() {
  }

  isMobile(){
    return this.helper.isMobile();
  }

}
