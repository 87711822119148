<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">
  <app-header></app-header>

  <div class="d-flex justify-content-center align-items-center p-5  header ">
    <span class="text-white d-block  text-center"> {{ 'just_call' | translate }} </span>
  </div>
  <div class="row">
    <div class="col-12 p-1 pl-3 pr-4 border-bottom border-gray-600">
      <a class="d-flex flex-row align-items-center justify-content-between  text-decoration-none not-link p-3 pl-5 ">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <span class="mdi mdi-email text-primary" style="font-size: 24px;"></span>
          <span class="text-xs align-middle text-dark pl-3 ">support@afrikpay.com</span>
        </div>
        <a href="mailto:support@afrikpay.com" class="btn btn-primary btn-sm text-xs"> {{ 'message_us' | translate}} </a>
      </a>
    </div>
    <div class="col-12 p-1 pl-3 pr-4 border-bottom border-gray-600">
      <a class="d-flex flex-row align-items-center justify-content-between  text-decoration-none not-link p-3 pl-5 ">
        <div class="d-flex flex-row align-items-center justify-content-center">
          <span class="mdi mdi-phone text-primary" style="font-size: 24px;"></span>
          <span class="text-xs align-middle text-dark pl-3">+237 658 880 708</span>
        </div>
        <a href="tel:+237658880708" class="btn btn-primary btn-sm text-xs"> {{ 'contact_us' | translate}} </a>
      </a>
    </div>
  </div>
</div>
<app-bottom-tab></app-bottom-tab>
