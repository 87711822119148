<!-- NAVBAR
    ================================================== -->
    <nav class="navbar navbar-expand-lg navbar-togglable">
        <div class="container-fluid">
      
          <!-- Brand -->
          <a class="navbar-brand d-flex align-items-center pb-3" href="/home">
            <img src="https://www.afrikpay.com/assets/img/logo.png" class="navbar-brand-img" alt="Logo AfrikPay">
          </a>
      
      
          <!-- Collapse -->
          <div class="collapse navbar-collapse" id="navbarCollapse">
      
            <!-- Navigation -->
            <ul class="navbar-nav ml-auto pt-2">
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-decoration-none" (click)="goTo('home/history')">
                  <i class="mdi mdi-history  text-primary mr-2" style="font-size: 16px;"></i> {{'history'| translate}}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-decoration-none" (click)="goTo('home/account')">
                  <i class="mdi mdi-account  text-primary mr-2" style="font-size: 16px;"></i> {{'account_settings'| translate}}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-decoration-none" (click)="goTo('home/pos')">
                  <i class="mdi mdi-account-search-outline  text-primary mr-2" style="font-size: 16px;"></i> {{'pos'| translate}}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-decoration-none" (click)="goTo('home/support')">
                  <i class="mdi mdi-face-agent  text-primary mr-2" style="font-size: 16px;"></i> {{'support'| translate}}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link d-flex align-items-center text-decoration-none" (click)="goTo('home/about')">
                  <i class="mdi mdi-information  text-primary mr-2" style="font-size: 16px;"></i> {{'more_info'| translate}}
                </a>
              </li>
            </ul>
      
          </div>
      
        </div>
      </nav>
      