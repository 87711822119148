import { Component, OnInit } from '@angular/core';
import { User } from '../classes';
import { HelperService } from '../helper.service';
import { HttpService } from '../http.service';
import { StoreService } from '../store.service';
import { FormControl } from '@angular/forms';
// import slide in/out animation
import { slideInOutAnimation } from '../_animations/index';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Md5 } from 'ts-md5';
declare var $: any;

@Component({
    selector: 'app-transfer-commission',
    templateUrl: './transfer-commission.component.html',
    styleUrls: ['./transfer-commission.component.css'],
    // make slide in/out animation available to this component
    animations: [slideInOutAnimation],

    // attach the slide in/out animation to the host (root) element of this component
    host: { '[@slideInOutAnimation]': '' },
})
export class TransferCommissionComponent {
    pinCode: string;
    modalTitle: string;
    modalText: string;
    modalType: string;

    isRecipientVisible = false;
    pPadVisible = false;
    numpad = [];

    amount: FormControl;

    amountError = false;

    lang: string;
    user: User;

    description: FormControl;
    mainBalance: number = 0;
    prevAmountValue: string = '';

    constructor(
        private helper: HelperService,
        private store: StoreService,
        private http: HttpService,
        private translate: TranslateService,
        private router: Router
    ) {}

    ngOnInit() {
        window.setInterval(() => {
            this.http.autoLogout();
        }, 1000);
        this.lang = localStorage.getItem('lang');
        this.user = this.store.getUser();
        this.pinCode = '';
        this.amount = new FormControl('');
        this.description = new FormControl('');
        this.balance();
    }

    _cleanString(str: any) {
        return str.replace(/\s/g, '');
    }

    _numberise(str: any) {
        str = this._cleanString(str);

        var output = '';

        for (var i = 0; i < str.length; i++) {
            if (!isNaN(str[i])) output += str[i];
        }

        return output;
    }

    onChangeText($event: any, key: string) {
        var value = $event.target.value;
        var output = '';
        var str = this._cleanString(value);

        if (key === 'amount' && value != '0' && parseInt(value) > 0) {
            let amount = parseInt(this._numberise(str));
            str = amount.toString();

            if (amount > this.mainBalance) {
                $('#modalBalanceAlert').modal('show');
                this.amount.setValue(this.prevAmountValue);
                return;
            }
        }

        var rest = str.length % 3;
        var part = Math.trunc(str.length / 3);

        if (str.length > 3) {
            output += str.substring(0, rest);
            str = str.slice(rest);

            for (var i = 0; i < part; i++) {
                output += ' ';
                output += str.substring(0, 3);
                str = str.slice(3);
            }
        } else {
            output = str;
        }

        switch (key) {
            case 'amount': {
                this.amount.setValue(output);
                this.prevAmountValue = output;
                break;
            }
        }
    }

    resetFormErrors() {
        this.amountError = false;
    }

    toggleModal(i: number) {
        $('#modalDetails').modal('hide');

        if (i == 0) {
            this.togglePad();
            return;
        }

        if (i == 1) {
            if (this.pinCode.length < 4) {
                if (!this.pPadVisible) this.togglePad();
                return;
            }

            this.transfer();
        }
    }

    proceed() {
        if (!(parseInt(this._cleanString(this.amount.value)) > 0)) {
            this.amountError = true;
            return;
        }

        this.togglePad();
    }

    transfer() {
        if (this.pinCode.length < 4) return;

        let data = {
            agentid: this.user.agent.idagent,
            password: this.user.agent.password,
            amount: this._cleanString(this.amount.value),
            pin: Md5.hashStr(this.pinCode),
            terminaltype: 'web',
            description: this.description.value,
        };

        this.togglePad();

        this.setModal('processing', 'please_wait', 'processing');
        $('#modalTransfer').modal('toggle');

        this.http.transferCommission(data).subscribe((response: any) => {
            if (response) {
                if (response.code === 200) {
                    this.closeOperation();
                } else {
                    this.modalText = response.message;
                    $('#modalTransfer').modal('hide');
                    $('#modalAlert').modal('show');
                }
            } else {
                this.setModal('warning', 'transaction_failed', '');
                this.dismissModal(2500, null);
            }
        });
    }

    closeOperation() {
        this.setModal('success', 'successfull', '');

        this.dismissModal(1500, null);
        this.pinCode = '';

        setTimeout(() => {
            this.router.navigateByUrl('/home');
        }, 2500);

        return;
    }

    setModal(type: string, text: string, title: string) {
        this.modalType = type;
        this.modalTitle = title;

        switch (type) {
            case 'danger': {
                this.modalText = text;
                break;
            }

            default: {
                this.translate.get(text).subscribe((res: string) => {
                    this.modalText = res;
                });
                break;
            }
        }
    }

    extractCommission(balance) {
        return balance ? (balance['mainbalance'] ? balance['mainbalance'] : 0) : 0;
    }

    balance() {
        let data = {
            phone: this.user.phonecompte,
            mode: 'agent',
            agentid: this.user.agent.idagent,
            agentpwd: this.user.agent.password,
        };

        this.setModal('processing', 'please_wait', 'checking_balance');
        $('#modalTransfer').modal('toggle');

        this.http.getBalance(data).subscribe((response: any) => {
            if (response) {
                if (response.code === 200) {
                    this.dismissModal(1, null);
                    this.mainBalance = this.extractCommission(response.result);
                } else {
                    this.setModal('danger', response.message || '', '');
                    this.dismissModal(2500, null);
                }
            } else {
                this.setModal('warning', 'failed', '');
                this.dismissModal(2500, null);
            }
        });
    }

    dismissModal(timer: number, dest: string) {
        setTimeout(() => {
            $('#modalTransfer').modal('hide');
            dest ? this.router.navigateByUrl(dest) : null;
        }, timer);
    }

    addDigit(d: string) {
        if (this.pinCode.length < 4) {
            this.pinCode = this.pinCode + d;
        }
    }

    deleteDigit() {
        this.pinCode.length > 0 ? (this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1)) : null;
    }

    togglePad() {
        this.pPadVisible = !this.pPadVisible;
        this.numpad = this.helper.getNumpad();
        this.pinCode = '';
    }

    isMobile() {
        return this.helper.isMobile();
    }
}
