import { Component, OnInit } from '@angular/core';
import { slideInOutAnimation } from '../_animations';
import { HelperService } from '../helper.service';
import {HttpService} from '../http.service';
import { User } from '../classes';
import {StoreService} from '../store.service';
import  * as moment from 'moment';
import 'moment/min/locales';
// import fade in animation
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
declare var  $:any;

@Component({
  selector: 'app-payrequest',
  templateUrl: './payrequest.component.html',
  styleUrls: ['./payrequest.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': ''
  }
})
export class PayrequestComponent implements OnInit {


  user : User;
  pinCode = '';
  pPadVisible = false;
  numpad = [];
  modalTitle: string;
  modalText: string;
  modalType: string;
  lang : string;
  list = [];
  list_loaded = false;
  status : string;
  item : number;

  constructor(private helper : HelperService, private http : HttpService, 
    private store : StoreService, private translate : TranslateService, private router : Router) { }

  ngOnInit() {

    this.user = this.store.getUser();
    this.loadRequest();
    this.lang = this.store.getLang();
  }

  isMobile(){
    return this.helper.isMobile();
  }

  loadRequest() {
    this.setModal('processing', 'please_wait', 'loading_transactions');

    $('#modalPayment').modal('show');

    this.list_loaded = false;

    this.http.getPayment(this.user.phonecompte).subscribe((response : any) => {
      if(response){
        if(response.code === 200){
          this.list = response.result;
          this.dismissModal(1);
        }else {
          this.setModal('danger', response.message || '', '');
          this.dismissModal(2500);
        }
      }else {
        this.setModal('warning', 'failed', '');
        this.dismissModal(2500);
      }
      
    this.list_loaded = true;
    },
    (error : any) => {

      if(error){
        this.setModal('warning', 'failed', '');
        this.dismissModal(2500);
        this.list_loaded = true;
      }

    })
  }

  changeStatus(status:string, item: number) {

    this.status = status;
    this.item = item;
    if(this.status === 'SUCCESS') {
      this.togglePad(true);
    }else {
      this.setTransaction();
    }

  }

  setTransaction(){

    
    if(this.pinCode.toString().length < 4 && this.status === 'SUCCESS') return;

    let pin = this.helper.encrypt(this.pinCode, this.user.phonecompte);

    this.togglePad(false);

    this.setModal('processing', 'please_wait', 'transaction_processing');

    $('#modalPayment').modal('show');

    this.http.setPaymentStatus(this.user.phonecompte, this.list[this.item].id, this.status, pin).subscribe((response : any) => {
      if(response){
        if(response.code === 200){
          this.list.splice(this.item,1);
          if(this.status === "SUCCESS"){
            this.setModal('success', 'approved_transaction', '');
          } else {
            this.setModal('success', 'rejected_transaction', '');
          }
          this.dismissModal(1500);
        }else {
          this.setModal('danger', response.message || '', '');
          this.dismissModal(2500);
        }
      }else {
        this.setModal('warning', 'failed', '');
        this.dismissModal(2500);
      }
      
    },
    (error : any) => {

      if(error){
        this.setModal('warning', 'failed', '');
        this.dismissModal(2500);
      }

    })
  }

  setModal(type : string, text : string, title : string) {

    this.modalType = type;
    this.modalTitle = title;

    switch (type) {
      case 'danger': {
        this.modalText = text;
        break;
      }

      default:{
        this.translate.get(text).subscribe((res: string) => {
          this.modalText = res;
        });
        break;
      }
    }

  }

  dismissModal(timer: number) {
    setTimeout(() => {
      $('#modalPayment').modal('hide');
    }, timer);
  }


  addDigit(d: string) {
    if (this.pinCode.length < 4) {
      this.pinCode = this.pinCode + d
    }
  }

  deleteDigit() {
    (this.pinCode.length > 0) ? this.pinCode = this.pinCode.substring(0, this.pinCode.length - 1): null;
  }

  togglePad(bool) {

    this.pPadVisible = bool;
    this.numpad = this.helper.getNumpad();

    this.pinCode = "";
  }

  getDate(date : string){
    return moment(date).locale(this.lang).format('LLL');
  }
  
}
