import { Component, OnInit } from '@angular/core';
import { slideInOutAnimation } from '../_animations';
import { HelperService } from '../helper.service';
import {HttpService} from '../http.service';
declare var $ : any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: {
    '[@slideInOutAnimation]': ''
  }
})
export class AboutComponent implements OnInit {

  list = [
    {
      title: 'who_we_are',
      content: 'who_we_are_content'
    },
    {
      title: 'our_vision',
      content: 'our_vision_content'
    },
    {
      title: 'our_values',
      content: 'our_values_content'
    },
    {
      title: 'become_merchant',
      content: 'become_content',
      link: 'https://www.afrikpay.com/merchant-request'
    },
    {
      title: 'become_agent',
      content: 'become_content',
      link: 'https://www.afrikpay.com/agent-request'
    }
  ];

  fcbk = 'https://www.facebook.com/Afrikpay/';
  twitter = 'https://twitter.com/afrikpay';
  lnkdin = 'https://www.linkedin.com/company/afrikpay/';

  selected = null;

  constructor(private helper: HelperService) { }

  ngOnInit() {
  }

  isMobile(){
    return this.helper.isMobile();
  }

  select(item : any){
    this.selected = item;
    $('#modalDetails').modal('show');
  }

}
