<div [ngClass]="{'pc-view' : !isMobile(), 'mobile-view': isMobile()}">
  <app-header></app-header>
  <div class="d-flex justify-content-center  align-items-center p-2  header ">
    <span class="text-white d-block  text-center"> {{ 'agents_pos' | translate }} </span>
  </div>
  <div class="p-1 d-flex justify-content-center align-items-center flex-row">
    <span class="mdi mdi-magnify text-gray-700" style="font-size: 30px;"></span>
    <div class="form-group has-search flex-fill">
      <input type="text" class="form-control" name="search" (ngModelChange)="searchText($event)" placeholder="{{'search' | translate}}" [(ngModel)]="search"  >
    </div>
  </div>
  <div class="row p-0 border-top border-gray-600" *ngIf="list?.length > 0">
    <div *ngFor="let item of theList()" class="col-12 d-flex flex-row justify-content-between align-items-center  border-bottom border-gray-600 p-2 pr-5 pl-5">
        <div class="d-flex flex-column justify-content-between">
            <span class="text-primary font-weight-bold">{{item.agentname}}</span>
            <div class="d-flex flex-row align-items-center">
                <span class="mdi mdi-near-me text-gray-400 form-control-feedback " style="font-size: 24x;"></span>
                <span class="text-muted text-xs  mt-2"> {{item.localisation}}</span>
            </div>
        </div>
        <div class="d-flex flex-column">
            <span class="text-gray-600 text-right">{{item.town}}</span>
            <span class="text-primary tex text-right mt-1">{{formatText(item.client)}}</span>
        </div>
    </div>
  </div>
  
  <div *ngIf="loading" class="container d-flex p-5 mt-1 flex-column justify-content-center align-items-center">
    <span class="spinner-border text-primary spinner-border-sm"></span>
  </div>

  <div class="d-flex justify-content-center p-5" *ngIf="search?.length >0 && filteredList?.length == 0" >
    <p class="text-xs">
      {{'no_data_found' | translate}}
    </p>
  </div>
</div>
