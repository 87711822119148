<div
    class="modal customModal"
    data-backdrop="static"
    data-keyboard="false"
    id="modalDialogBox"
    tabindex="-1"
    role="dialog"
>
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header bg-success">
                <h5 *ngIf="db" class="modal-title text-white">{{ db.getTitle() | translate }}</h5>
            </div>
            <div class="modal-body">
                <div *ngIf="db" class="row border-top border-gray-600 mb-1">
                    <div *ngFor="let ref of db.getSubtitles()" class="col-12 col-md-12 d-flex d-block px-3 py-1">
                        <span class="text-xs text-muted mr-2"> {{ ref.key | translate }} : </span>
                        <span class="text-primary text-xs font-weight-bold text-muted"> {{ ref.value }}</span>
                    </div>
                </div>
                <div *ngIf="db" class="row border-top border-gray-600">
                    <div
                        *ngFor="let ref of db.getDataList()"
                        class="col-12 col-md-12 d-flex flex-column d-block px-3 py-1"
                    >
                        <span class="text-xs text-muted mb-0"> {{ ref.key }} </span>
                        <span class="text-primary text-xs font-weight-bold"> {{ ref.value }}</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-flex justify-content-between">
                <button type="button" (click)="cancel()" class="btn btn-secondary btn-sm">
                    {{ "quit" | translate }}
                </button>
                <button type="button" class="btn btn-primary btn-sm" (click)="confirm()">
                    {{ "download" | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
