<div class="fixed-bottom d-md-none bottomTab border-top border-gray-300">
  <div class="row pt-sm-0 pt-md-0">
    <div class="col-sm-6 col-6">
      <div class="row">
        <div class=" col-sm-6 col-6">
          <a class="d-flex flex-md-row flex-column align-items-center align-middle text-decoration-none" routerLink="/home">
            <span class="mdi mdi-view-dashboard text-primary font-16"
              [ngClass]=" {'font-20' : currentRoute === 'home'} "></span>
            <span class="text-center text-xs align-middle text-dark pl-md-2 pl-sm-2" *ngIf="currentRoute === 'home'">
              {{ 'dashboard' | translate }}
            </span>
          </a>
        </div>
        <div class=" col-sm-6 col-6">
          <a class="d-flex flex-md-row flex-column align-items-center text-decoration-none" routerLink="/account">
            <span class="mdi mdi-account text-primary font-16"
              [ngClass]=" {'font-20' : currentRoute === 'account'} "></span>
            <span class="text-center text-xs align-middle text-dark pl-md-2 pl-sm-2" *ngIf="currentRoute === 'account'">
              {{ 'account' | translate }}
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="col-sm-6 col-6">
      <div class="row">
        <div class=" col-sm-6 col-6">
          <a class="d-flex flex-md-row flex-column align-items-center align-middle text-decoration-none"
            routerLink="/support">
            <span class="mdi mdi mdi-face-agent text-primary font-16"
              [ngClass]=" {'font-20' : currentRoute === 'support'} "></span>
            <span class="text-center text-xs align-middle text-dark pl-md-2 pl-sm-2" *ngIf="currentRoute === 'support'">
              {{ 'support' | translate }}
            </span>
          </a>
        </div>
        <div class=" col-sm-6 col-6">
          <a class="d-flex flex-md-row flex-column align-items-center align-middle text-decoration-none"
            routerLink="/about">
            <span class="mdi mdi-information text-primary font-16"
              [ngClass]=" {'font-20' : currentRoute === 'about'} "></span>
            <span class="text-center text-xs align-middle text-dark pl-md-2 pl-sm-2" *ngIf="currentRoute === 'about'">
              {{ 'more_info' | translate }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
