import { Component, OnInit } from '@angular/core';
// import slide in/out animation
import { slideInOutAnimation } from '../_animations/index';
import {HttpService} from '../http.service';
import { HelperService } from '../helper.service';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.css'],
  // make slide in/out animation available to this component
  animations: [slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class PosComponent implements OnInit {

  list  = [];
  filteredList = [];
  search = "";
  loading = true;

  constructor(private http : HttpService, private helper : HelperService) { }

  ngOnInit() {
    this.getAgents();
  }

  theList(){
    return (this.search.length > 0) ? this.filteredList : this.list;
  }


  searchText = (text : string) => {

    this.search = text;

		let filteredName = this.list.filter((item) => {
			return (
				item.agentname.toLowerCase().match(text) ||
				item.town.toLowerCase().match(text) ||
				item.localisation.toLowerCase().match(text)||
				item.client.toString().match(text)
			);
    });
		if (!text || text === '') {
			this.filteredList = this.list;
		} else if (Array.isArray(filteredName)) {
			this.filteredList = filteredName;
		}
	};


  formatText(value) {
		var output = '';
		var str = value || '';

		var rest = str.length % 3;
		var part = Math.trunc(str.length / 3);

		if (str.length > 3) {
			output += str.substring(0, rest);
			str = str.slice(rest);

			for (var i = 0; i < part; i++) {
				output += ' ';
				output += str.substring(0, 3);
				str = str.slice(3);
      }
      
		} else {
			output = str;
		}
		return output;
  }

  getAgents(){
    this.http.getAllAgent().subscribe((response : any) => {
      if(response){
        if(response.code === 200) {
          this.list = response.result;
        }
      }
      this.loading = false;
    },
    (erro) => {
      this.loading = false;
    })
  }

  isMobile(){
    return this.helper.isMobile();
  }

}


